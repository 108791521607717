import { useMemo, useState } from "react";
import useLoadingStatus from "../../../../../hooks/useLoadingStatus";
import { QuestionnaireReportProps } from "./QuestionnaireReport";
import { questionList } from "./structureObject";
import { getAverageOfListOfNumbers } from "../../../functions/utility";

export function useQuestionnaireReport({
  globalFilteredData,
}: QuestionnaireReportProps) {
  const [selectedNames, setSelectedNames] = useState<string[]>([]);

  const summaryData = useMemo(() => {
    if (selectedNames.length !== 1) return null;
    return globalFilteredData.find((row) => row.name === selectedNames[0]);
  }, [selectedNames, globalFilteredData]);

  const names = useMemo(
    () => globalFilteredData.map((row) => row.name),
    [globalFilteredData]
  );

  const loading = useLoadingStatus([
    window.location.hostname === "localhost"
      ? "c6b0e19b-252f-4166-836f-1ff9f4d817a9"
      : "6e246f3e-2b6c-487e-97a7-8d233912c6aa",
  ]);

  const filteredScores = useMemo(
    () =>
      globalFilteredData.filter(
        (row) =>
          selectedNames.length === 0 ||
          selectedNames.includes(row.name.toString())
      ),
    [globalFilteredData, selectedNames]
  );

  const scoreListObject = useMemo(() => {
    return filteredScores.reduce((obj, row) => {
      for (let i = 1; i < 37; i++) {
        if (!obj[`q${i}`]) obj[`q${i}`] = [];
        obj[`q${i}`].push(row[`q${i}`]);
      }
      return obj;
    }, {});
  }, [filteredScores]);

  const scoresObject = useMemo(() => {
    return Object.keys(scoreListObject).reduce(
      (obj: { [key: string]: number }, key) => {
        obj[key.toString()] = getAverageOfListOfNumbers(scoreListObject[key]);
        return obj;
      },
      {}
    );
  }, [scoreListObject]);

  //@ts-ignores
  const groupedBehaviours = Object.groupBy(
    questionList, //@ts-ignores
    (row) => row.behaviour
  );

  const scoredGroups = Object.keys(groupedBehaviours).map((key) => {
    return {
      name: key,
      behaviour: key, //@ts-ignores
      list: groupedBehaviours[key],
      score: getAverageOfListOfNumbers(
        //@ts-ignore
        groupedBehaviours[key].map((record) => scoresObject[record.q])
      ),
    };
  });

  const sortedScoredGroups = scoredGroups.sort(
    (a: any, b: any) => b.score - a.score
  );

  //@ts-ignore
  const topStrengthsThreshold = sortedScoredGroups.at(2)
    ? //@ts-ignore
      sortedScoredGroups.at(2).score
    : 5;
  //@ts-ignore
  const bottomStrengthsThreshold = sortedScoredGroups.at(-3)
    ? //@ts-ignore
      sortedScoredGroups.at(-3).score
    : 0;

  return {
    loading,
    names,
    selectedNames,
    setSelectedNames,
    questionList,
    scoresObject,
    topStrengthsThreshold,
    bottomStrengthsThreshold,
    scoredGroups,
    summaryData,
  };
}
