import { useContext, useEffect, useMemo, useState } from "react";
import {
  IconAntennaBars5,
  IconBuilding,
  IconBulb,
  IconCloud,
  IconLink,
  IconTrophy,
} from "@tabler/icons-react";
import useLoadingStatus from "../../../../../hooks/useLoadingStatus";
import AppContext from "../../../../../context/AppContext";

const temperaments = [
  {
    title: "Imagining",
    color: "orange",
    Icon: IconCloud,
    needs: "Attention & Recognition",
    drivers: "Purpose",
    behaviouralTraits: ["Creative", "Purposeful", "Spontaneous", "Flexible"],
    description: [
      "In this mindset, you are paying attention to the outside world. You are mindful of who you are in the world and your ability to make a difference.",
      "You are likely to thrive on spontaneity, making things happen and doing things that are purposeful.",
      "he dark side... You may be overly sensitive to recognition, whether that’s getting the credit you deserve or diverting recognition onto others who you think deserve it.",
    ],
    potentialClashes: [
      "Energetically Enthusiastic - Needs Agility",
      "Feels restricted by analysis & rigid compliance",
      "Responds with blind intensity, chaos & delusion.",
    ],
    esteem: "Significant",
    withdrawn: "Invisible",
    balanced: "Attentive",
    excessive: "Self Promotional",
    clashes: [
      { name: "Building", color: "violet" },
      { name: "Evaluating", color: "blue" },
      { name: "Enabling", color: "pink" },
    ],
  },
  {
    title: "Innovating",
    color: "green",
    Icon: IconBulb,
    needs: "Status & Influence",
    drivers: "Impact",
    description: [
      "In this mindset, you are paying attention to the outside world and your internal evaluations. You are mindful of your ability to make a difference, tempered by your ability to do things well.",
      "You are likely to thrive on raising the bar, making things happen and doing purposeful things.",
      "The dark side... You may be overly sensitive to recognition and evaluation.",
    ],
    behaviouralTraits: ["Logical", "Resilient", "Aligned", "Impactful"],
    potentialClashes: [
      "Proactive & Impactful - Needs Alignment",
      "Disengages from deliberation & compromise",
      "Responds with argument, conflict & exclusion",
    ],
    esteem: "Valued",
    withdrawn: "Exclusive",
    balanced: "Inclusive",
    excessive: "Overly Inclusive",
    clashes: [
      { name: "Enabling", color: "pink" },
      { name: "Building", color: "violet" },
      { name: "Achieving", color: "red" },
    ],
  },
  {
    title: "Evaluating",
    color: "blue",
    Icon: IconAntennaBars5,
    needs: "Power & Control",
    drivers: "Mastery",
    description: [
      "In this mindset, you are paying attention to your thoughts and judgements. You are mindful of your competence in the world and your ability to do things well.",
      "You are likely to thrive on raising the bar, gaining knowledge, and growing as an individual, team and organisation.",
      "The dark side... You hate falling short of the mark, and you are most comfortable when you are completely in control or ‘wash your hands’ completely. You need to know who is in charge, and you are sensitive to evaluation,",
    ],
    behaviouralTraits: ["Practical", "Clear", "Reliable", "In Control"],
    potentialClashes: [
      "Reliable & In Control - Needs clarity",
      "Frustrated by flexible, creative action",
      "Responds with bureaucracy or micro management",
    ],
    esteem: "Competent",
    withdrawn: "Abdicratic",
    balanced: "Consultative",
    excessive: "Autocratic",
    clashes: [
      { name: "Achieving", color: "red" },
      { name: "Enabling", color: "pink" },
      { name: "Imagining", color: "orange" },
    ],
  },
  {
    title: "Building",
    color: "violet",
    Icon: IconBuilding,
    needs: "Cohesion & Respect",
    drivers: "Growth",
    description: [
      "In this mindset, you are paying attention to your thoughts and feelings.",
      "You are mindful of the quality of relationships and ability to get things done well.",
      "You are likely to thrive on authentic teamwork, raising the bar, and growing as an individual, team and organisation.",
      "The dark side... You may be overly sensitive to harmony/conflict and the opinions of others.",
    ],
    behaviouralTraits: ["Measured", "Understanding", "Calm", "Supportive"],
    potentialClashes: [
      "Measured & Analytical - Needs Transparency",
      "Confused by opacity & blind enthusiasm",
      "Responds with forced compliance or withdrawal",
    ],
    esteem: "Secure",
    withdrawn: "Apathetic",
    balanced: "Supportive",
    excessive: "Demanding",
    clashes: [
      { name: "Imagining", color: "orange" },
      { name: "Achieving", color: "red" },
      { name: "Innovating", color: "green" },
    ],
  },
  {
    title: "Enabling",
    color: "pink",
    Icon: IconLink,
    needs: "Trust & Boundaries",
    drivers: "Connection",
    description: [
      "In this mindset, you are paying attention to your feelings, especially in relation to people.",
      "You are mindful of the quality of relationships. You are likely to thrive in a great culture and close teamwork with open, authentic people.",
      "The dark side... You hate to be disliked and are most comfortable when you know where you stand with others. You may be overly sensitive to harmony or conflict.",
    ],
    behaviouralTraits: ["Empathetic", "Caring", "Aware", "Transparent"],
    potentialClashes: [
      "Empathetic & Caring - Needs Connection",
      "Upset by individual focus, conflict & hostility",
      "Responds with groupthink & indecisiveness",
    ],
    esteem: "Liked",
    withdrawn: "Secretive",
    balanced: "Authentic",
    excessive: "Over Sharing",
    clashes: [
      { name: "Innovating", color: "green" },
      { name: "Imagining", color: "orange" },
      { name: "Evaluating", color: "blue" },
    ],
  },
  {
    title: "Achieving",
    color: "red",
    Icon: IconTrophy,
    needs: "Collaboration & Safety",
    drivers: "Interaction",
    description: [
      "In this mindset, you are paying attention to the outside world and your internal feelings about it. You are mindful of the quality of relationships and your ability to make a difference.",
      "You are likely to thrive on connection, energy and working as a team to achieve something purposeful.",
      "The dark side... You may be overly sensitive to recognition, harmony or conflict.",
    ],
    behaviouralTraits: ["Collaborative", "Adaptable", "Relevant", "Engaged"],
    potentialClashes: [
      "Collaborative & Adaptable - Needs safety",
      "Sensitive to micro-management & austerity",
      "Responds with permissiveness or avoidance",
    ],
    esteem: "Included",
    withdrawn: "Political",
    balanced: "Transparent",
    excessive: "Indiscreet",
    clashes: [
      { name: "Evaluating", color: "blue" },
      { name: "Building", color: "violet" },
      { name: "Innovating", color: "green" },
    ],
  },
];

export function useTemperamentsReport({ theme, globalFilteredData }: any) {
  const { groups, ownerEmail } = useContext(AppContext);
  const [currentTemperament, setCurrentTemperament] = useState<any>(null);
  const [currentResult, setCurrentResult] = useState<any>(null);
  const [view, setView] = useState<"main" | "table">("main");

  const alwaysView = useMemo(
    () =>
      [
        // "bradleyparry@smarttasking.com",
        "lindsey.ulanowsky@positivemomentum.com",
        "ds@daryllscott.com",
      ].includes(ownerEmail),
    [ownerEmail]
  );

  // const loading = useLoadingStatus(["4363bc08-b0bf-4667-913e-06f280128d84"]); // dev
  // const loading = useLoadingStatus(["2ffafde5-20eb-4ccc-8989-60ad61a51e04"]); // main

  const loading = useLoadingStatus([
    window.location.hostname === "localhost"
      ? "4363bc08-b0bf-4667-913e-06f280128d84"
      : "2ffafde5-20eb-4ccc-8989-60ad61a51e04",
  ]);

  useEffect(() => {
    // if (globalFilteredData.length === 1) {
    // console.log({ globalFilteredData, groups });
    const viewerResult = globalFilteredData.find((result: any) =>
      groups.includes(result.email)
    );

    // console.log({ viewerResult });

    if (viewerResult) {
      const { temperament, name } = viewerResult;

      setCurrentTemperament(temperament);

      const { color } =
        temperaments.find((temp: any) => temp.title === temperament) ?? {};

      setCurrentResult({ name, temperament, color });
    } else if (alwaysView) {
      setCurrentTemperament("Imagining");
    } else {
      setCurrentResult(null);
    }
  }, [globalFilteredData, groups, alwaysView]);

  // console.log({ currentResult });

  const temperamentObject = useMemo(() => {
    return temperaments.find(
      (temperament) => temperament.title === currentTemperament
    );
  }, [currentTemperament]);

  return {
    temperaments,
    temperamentObject,
    setCurrentTemperament,
    view,
    setView,
    currentResult,
    loading,
    alwaysView,
  };
}
