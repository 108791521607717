import { NativeSelect, TextInput } from "@mantine/core";

export default function SystemSpecificData({ form }: any) {
  switch (form.values.system) {
    case "monday":
      return (
        <>
          <NativeSelect
            label={"Select operation"}
            mb={"md"}
            data={[
              { value: "", label: "" },
              { value: "getBoards", label: "Get Boards" },
              { value: "getBoardItems", label: "Get Board Items" },
              {
                value: "getBoardItemsWithLinks",
                label: "Get Board Items With Links",
              },
              { value: "getBoardSubItems", label: "Get Board Sub Items" },
              { value: "getUsers", label: "Get Users" },
            ]}
            value={form.values.dataObject?.systemSpecificData?.operation ?? ""}
            onChange={(e) => {
              const dataObjectCopy = structuredClone(form.values.dataObject);
              dataObjectCopy.systemSpecificData.operation = e.target.value;
              form.setValues({ dataObject: dataObjectCopy });
            }}
          />
          <TextInput
            label="Monday Board Id"
            value={form.values.dataObject?.systemSpecificData?.boardId ?? ""}
            onChange={(e) => {
              const dataObjectCopy = structuredClone(form.values.dataObject);
              dataObjectCopy.systemSpecificData.boardId = e.target.value;
              form.setValues({ dataObject: dataObjectCopy });
            }}
          />
          {/* <TextInput
            label="Monday Workspace Id"
            value={
              form.values.dataObject?.systemSpecificData?.workspaceId ?? ""
            }
            onChange={(e) => {
              const dataObjectCopy = structuredClone(form.values.dataObject);
              dataObjectCopy.systemSpecificData.workspaceId = e.target.value;
              form.setValues({ dataObject: dataObjectCopy });
            }}
          /> */}
        </>
      );
    case "tsp":
      return (
        <>
          <NativeSelect
            label={"Select operation"}
            mb={"md"}
            data={[
              { value: "", label: "" },
              { value: "getInvoices", label: "Get Invoices" },
              { value: "getTimesheets", label: "Get Time Sheets" },
            ]}
            value={form.values.dataObject?.systemSpecificData?.operation ?? ""}
            onChange={(e) => {
              const dataObjectCopy = structuredClone(form.values.dataObject);
              dataObjectCopy.systemSpecificData.operation = e.target.value;
              form.setValues({ dataObject: dataObjectCopy });
            }}
          />
          {/* <TextInput
            label="Client ID"
            value={form.values.dataObject?.systemSpecificData?.boardId ?? ""}
            onChange={(e) => {
              const dataObjectCopy = structuredClone(form.values.dataObject);
              dataObjectCopy.systemSpecificData.boardId = e.target.value;
              form.setValues({ dataObject: dataObjectCopy });
            }}
            mb="md"
          />
          <TextInput
            label="Client Secret"
            value={
              form.values.dataObject?.systemSpecificData?.workspaceId ?? ""
            }
            onChange={(e) => {
              const dataObjectCopy = structuredClone(form.values.dataObject);
              dataObjectCopy.systemSpecificData.workspaceId = e.target.value;
              form.setValues({ dataObject: dataObjectCopy });
            }}
            mb="md"
          /> */}
        </>
      );
    case "bigin":
      return (
        <NativeSelect
          label={"Select source entity"}
          mb={"md"}
          data={[
            { value: "", label: "" },
            { value: "getDeals", label: "Deals" },
          ]}
          value={form.values.dataObject?.systemSpecificData?.operation ?? ""}
          onChange={(e) => {
            const dataObjectCopy = structuredClone(form.values.dataObject);
            dataObjectCopy.systemSpecificData.operation = e.target.value;
            form.setValues({ dataObject: dataObjectCopy });
          }}
        />
      );
    case "hubspot":
      return (
        <NativeSelect
          label={"Select source entity"}
          mb={"md"}
          data={[
            { value: "", label: "" },
            { value: "getDeals", label: "Deals" },
            { value: "getDealsGroupedByPos", label: "Deals Grouped By POs" },
            { value: "getCompanies", label: "Companies" },
            { value: "getProducts", label: "Products" },
          ]}
          value={form.values.dataObject?.systemSpecificData?.operation ?? ""}
          onChange={(e) => {
            const dataObjectCopy = structuredClone(form.values.dataObject);
            dataObjectCopy.systemSpecificData.operation = e.target.value;
            form.setValues({ dataObject: dataObjectCopy });
          }}
        />
      );
    case "microsoft":
      return (
        <NativeSelect
          label={"Select operation"}
          mb={"md"}
          data={[
            { value: "", label: "" },
            {
              value: "getActiveDirectoryUsers",
              label: "Active Directory Users",
            },
          ]}
          value={form.values.dataObject?.systemSpecificData?.operation ?? ""}
          onChange={(e) => {
            const dataObjectCopy = structuredClone(form.values.dataObject);
            dataObjectCopy.systemSpecificData.operation = e.target.value;
            form.setValues({ dataObject: dataObjectCopy });
          }}
        />
      );
    case "excel":
      return (
        <>
          <NativeSelect
            label={"Select operation"}
            mb={"md"}
            data={[
              { value: "", label: "" },
              { value: "getWorksheetData", label: "Get Worksheet Data" },
            ]}
            value={form.values.dataObject?.systemSpecificData?.operation ?? ""}
            onChange={(e) => {
              const dataObjectCopy = structuredClone(form.values.dataObject);
              dataObjectCopy.systemSpecificData.operation = e.target.value;
              form.setValues({ dataObject: dataObjectCopy });
            }}
          />
          <TextInput
            label="Drive Id"
            value={form.values.dataObject?.systemSpecificData?.driveId ?? ""}
            onChange={(e) => {
              const dataObjectCopy = structuredClone(form.values.dataObject);
              dataObjectCopy.systemSpecificData.driveId = e.target.value;
              form.setValues({ dataObject: dataObjectCopy });
            }}
          />
          <TextInput
            label="File Id"
            value={form.values.dataObject?.systemSpecificData?.fileId ?? ""}
            onChange={(e) => {
              const dataObjectCopy = structuredClone(form.values.dataObject);
              dataObjectCopy.systemSpecificData.fileId = e.target.value;
              form.setValues({ dataObject: dataObjectCopy });
            }}
          />
          <TextInput
            label="Worksheet Id"
            value={
              form.values.dataObject?.systemSpecificData?.worksheetId ?? ""
            }
            onChange={(e) => {
              const dataObjectCopy = structuredClone(form.values.dataObject);
              dataObjectCopy.systemSpecificData.worksheetId = e.target.value;
              form.setValues({ dataObject: dataObjectCopy });
            }}
          />
        </>
      );
    default:
      return null;
  }
}
