import React, { useCallback } from "react";
import useStyles from "./QuestionnaireReport.styles";
import { useQuestionnaireReport } from "./useQuestionnaireReport";
import WidgetFrame from "../../../WidgetFrame";
import {
  Badge,
  Center,
  Divider,
  Group,
  Image,
  LoadingOverlay,
  MultiSelect,
  Popover,
  SimpleGrid,
  Stack,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import {
  Behaviour,
  Pillar,
  Question,
  QuestionList,
  structureObject,
} from "./structureObject";
import { getAverageOfListOfNumbers } from "../../../functions/utility";
import { IconInfoCircle } from "@tabler/icons-react";
import deliver from "./images/deliver outcomes.png";
import develop from "./images/develop our people.png";
import drive from "./images/drive value.png";
import inspire from "./images/inspire belief.png";
import ColumnChart from "../../../graphs/ColumnChart";
import Placeholder from "../../../Placeholder";

const imageLookup: Record<Pillar, string> = {
  "DEVELOP OUR PEOPLE": develop,
  "INSPIRE BELIEF": inspire,
  "DELIVER OUTCOMES": deliver,
  "DRIVE VALUE": drive,
};

function PillarSummary({
  name,
  records,
  scoresObject,
  topStrengthsThreshold,
  bottomStrengthsThreshold,
}: {
  name: Pillar;
  records: any[];
  scoresObject: any;
  topStrengthsThreshold: number;
  bottomStrengthsThreshold: number;
}) {
  const total = getAverageOfListOfNumbers(
    records.map((record) => scoresObject[record.q])
  );
  //@ts-ignore
  const groupedBehaviours = Object.groupBy(records, (row) => row.behaviour);

  const scoredGroups = Object.keys(groupedBehaviours).map((key) => {
    return {
      behaviour: key,
      list: groupedBehaviours[key],
      score: getAverageOfListOfNumbers(
        //@ts-ignore
        groupedBehaviours[key].map((record) => scoresObject[record.q])
      ),
    };
  });

  const sortedScoredGroups = scoredGroups.sort(
    (a: any, b: any) => b.score - a.score
  );

  const { description } = structureObject[name];

  //@ts-ignore

  const getColour = useCallback(
    (score: number) => {
      return score >= topStrengthsThreshold
        ? "green"
        : score <= bottomStrengthsThreshold
        ? "red"
        : "orange";
    },
    [topStrengthsThreshold, bottomStrengthsThreshold]
  );

  return (
    <Stack align="center">
      <Group spacing={"xs"}>
        <Text fw={700}>{name}</Text>
        <Popover withArrow shadow="md" width={400}>
          <Popover.Target>
            <ThemeIcon
              size={"xs"}
              style={{ cursor: "pointer" }}
              variant="light"
            >
              <IconInfoCircle />
            </ThemeIcon>
          </Popover.Target>
          <Popover.Dropdown>
            <Text size="sm">{description}</Text>
          </Popover.Dropdown>
        </Popover>
      </Group>
      <Text size={"3em"} fw={700} color="pink">
        {total.toFixed(2)}
      </Text>
      <div
        style={{
          height: 210,
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        {/* 
      //@ts-ignore */}
        <Image src={imageLookup[name]} width={250} />
      </div>

      {sortedScoredGroups.map((object: any) => {
        const { behaviour, score } = object;
        const { description, questions } =
          //@ts-ignore
          structureObject[name].behaviours[behaviour];

        return (
          <Group
            position="apart"
            style={{ width: "100%" }}
            px={"lg"}
            spacing={"xs"}
          >
            <Group spacing={"xs"}>
              <Text>{behaviour}</Text>
              <Popover withArrow shadow="md" width={500}>
                <Popover.Target>
                  <ThemeIcon
                    size={"xs"}
                    style={{ cursor: "pointer" }}
                    variant="light"
                  >
                    <IconInfoCircle />
                  </ThemeIcon>
                </Popover.Target>
                <Popover.Dropdown>
                  <Stack>
                    <Text size="sm">{description}</Text>
                    <Divider />
                    <SimpleGrid
                      cols={2}
                      style={{ gridTemplateColumns: "1fr auto" }}
                    >
                      {questions
                        .sort(
                          (a: any, b: any) =>
                            scoresObject[b.q] - scoresObject[a.q]
                        )
                        .map((question: any) => {
                          return (
                            <>
                              <Text size="xs">{question.description}</Text>
                              <Badge>
                                {scoresObject[question.q].toFixed(2)}
                              </Badge>
                            </>
                          );
                        })}
                    </SimpleGrid>
                  </Stack>
                </Popover.Dropdown>
              </Popover>
            </Group>
            <Badge size="lg" color={getColour(score)}>
              {score.toFixed(2)}
            </Badge>
          </Group>
        );
      })}
    </Stack>
  );
}

function PillarSummaries({
  questionList,
  scoresObject,
  topStrengthsThreshold,
  bottomStrengthsThreshold,
}: {
  questionList: QuestionList;
  scoresObject: any;
  topStrengthsThreshold: number;
  bottomStrengthsThreshold: number;
}) {
  //@ts-ignore
  const pillarGroups = Object.groupBy(
    questionList,
    (row: Question) => row.pillar
  );

  const scoredGroups = Object.keys(pillarGroups).map((key) => {
    return {
      pillar: key, //@ts-ignore
      list: pillarGroups[key],
      score: getAverageOfListOfNumbers(
        //@ts-ignore
        pillarGroups[key].map((record) => scoresObject[record.q])
      ),
    };
  });

  return (
    <>
      {scoredGroups
        .sort((a: any, b: any) => b.score - a.score)
        .map((object: any) => {
          const { pillar } = object;
          return (
            <PillarSummary
              name={pillar as Pillar} //@ts-ignore
              records={pillarGroups[pillar]}
              scoresObject={scoresObject}
              topStrengthsThreshold={topStrengthsThreshold}
              bottomStrengthsThreshold={bottomStrengthsThreshold}
            />
          );
        })}
    </>
  );
}

export type QuestionnaireReportProps = { globalFilteredData: any[] };

export function QuestionnaireReport(props: QuestionnaireReportProps) {
  const { classes, theme } = useStyles();
  const {
    loading,
    names,
    selectedNames,
    setSelectedNames,
    questionList,
    scoresObject,
    topStrengthsThreshold,
    bottomStrengthsThreshold,
    scoredGroups,
    summaryData,
  } = useQuestionnaireReport(props);

  return (
    <WidgetFrame
      title={"Questionnaire Report"}
      controls={
        <>
          {names.length > 1 && (
            <MultiSelect
              value={selectedNames}
              placeholder="All records"
              clearable
              searchable
              data={names}
              onChange={setSelectedNames}
              styles={{
                input: {
                  flexGrow: 1,
                  backgroundColor:
                    theme.colorScheme === "dark"
                      ? theme.colors.dark[4]
                      : undefined,
                },
              }}
            />
          )}
          {names.length === 1 && (
            <Text fw={700} size={"xl"}>
              {names[0]}
            </Text>
          )}
        </>
      }
    >
      {props.globalFilteredData.length === 0 && (
        <Placeholder placeholderText="No results" />
      )}
      <LoadingOverlay visible={loading} />
      {!loading && (
        <div style={{ overflow: "scroll" }}>
          {summaryData && (
            <>
              <Divider />
              <Center my="xl">
                <Title order={3} underline color="dimmed">
                  Information
                </Title>
              </Center>
              <Group my={"lg"} position="apart">
                <Group>
                  <Text fw={700} color="pink">
                    Role Title:
                  </Text>
                  <Text>{summaryData.role_title}</Text>
                </Group>
                <Group>
                  <Text fw={700} color="pink">
                    Department:
                  </Text>
                  <Text>{summaryData.department}</Text>
                </Group>
                <Group>
                  <Text fw={700} color="pink">
                    Length of time in your current role:
                  </Text>
                  <Text>{summaryData.length_of_time_in_your_current_role}</Text>
                </Group>
                <Group>
                  <Text fw={700} color="pink">
                    Number of Direct Reports:
                  </Text>
                  <Text>{summaryData.number_of_direct_reports}</Text>
                </Group>
              </Group>
              <SimpleGrid
                my={"lg"}
                spacing={"xl"}
                breakpoints={[
                  { minWidth: "sm", cols: 1, spacing: "3em" },
                  { minWidth: "md", cols: 1, spacing: "xl" },
                  { minWidth: "lg", cols: 2, spacing: "md" },
                ]}
              >
                <Stack spacing={"xs"}>
                  <Text fw={700} color="pink">
                    Strengths:
                  </Text>
                  <Stack>
                    <Text>- {summaryData.strength_1}</Text>
                    <Text>- {summaryData.strength_2}</Text>
                    <Text>- {summaryData.strength_3}</Text>
                  </Stack>
                </Stack>
                <Stack spacing={"xs"}>
                  <Text fw={700} color="pink">
                    Develop:
                  </Text>
                  <Stack>
                    <Text>- {summaryData.develop_1}</Text>
                    <Text>- {summaryData.develop_2}</Text>
                    <Text>- {summaryData.develop_3}</Text>
                  </Stack>
                </Stack>
              </SimpleGrid>
              <Divider />
            </>
          )}
          <Center my="xl">
            <Title order={3} underline color="dimmed">
              Results
            </Title>
          </Center>
          <SimpleGrid
            breakpoints={[
              { minWidth: "sm", cols: 1, spacing: "3em" },
              { minWidth: "md", cols: 2, spacing: "xl" },
              { minWidth: "lg", cols: 4, spacing: "md" },
            ]}
          >
            <PillarSummaries
              questionList={questionList}
              scoresObject={scoresObject}
              topStrengthsThreshold={topStrengthsThreshold}
              bottomStrengthsThreshold={bottomStrengthsThreshold}
            />
          </SimpleGrid>
          <Group position="center" my="lg">
            <Badge color="green">High Score</Badge>
            <Badge color="orange">Moderate Score</Badge>
            <Badge color="red">Low Score</Badge>
          </Group>
          <div style={{ height: 400, marginTop: "3em" }}>
            <ColumnChart
              data={scoredGroups}
              dataLabels={true}
              values={["score"]}
              yAxisWidth={60}
              dataLabelFontSize={1}
              yAxisFontSize={1}
              xAxisFontSize={0.75}
              formatFunction={(value: any) => value.toFixed(2)}
              legend={false}
              chartVariant={""}
              colKeys={[]}
              rowKeys={scoredGroups.map((score) => score.behaviour)}
              includeYAxis={true}
              includeXAxis={true}
              updateDashboardFilter={() => null}
              rows={["behaviour"]}
              cols={[]}
              schema={{}}
              yAxisMin={""}
              yAxisMax={""}
              grid={true}
              comparisonFieldString={"None"}
              disableChartInteractions={true}
              disableTooltip={false}
              disableAnimations={false}
              xAngle={-45}
              xTextAnchor={"end"}
              xAxisHeight={70}
            />
          </div>
        </div>
      )}
    </WidgetFrame>
  );
}
