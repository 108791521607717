import React from "react";
import { useContext, useMemo } from "react";
import { createFilterFunction } from "../../functions/createFilterFunction";
import { useElementSize } from "@mantine/hooks";
import AppContext from "../../../../context/AppContext";
import useLoadingStatus from "../../../../hooks/useLoadingStatus";
// import { calculatePercentageOfDurationPassed } from "../../functions/dateFunctions";
import { getRevenueStatusObjectFromProjectList } from "../../functions/getRevenueStatusObjectFromProjectList";

export function useFinanceVisual({
  dashboardFilterObjectList,
  globalFilteredData,
  startDate,
  endDate,
}: any) {
  const { ref, width, height } = useElementSize();
  const { currentProgrammes } = useContext(AppContext);
  // const { projects = [] } = dataObject;

  const localFilterFunction = useMemo(
    () =>
      createFilterFunction(
        [dashboardFilterObjectList],
        "associatedDealCloseDate", //"startDate",
        startDate,
        endDate
      ),
    [dashboardFilterObjectList, startDate, endDate]
  );

  const filteredProjects = useMemo(() => {
    return globalFilteredData
      .filter(
        (project: any) =>
          project.period === "Live" &&
          currentProgrammes.includes(project.programmeProjectsId)
      )
      .filter(localFilterFunction);
  }, [globalFilteredData, localFilterFunction, currentProgrammes]);

  const loading = useLoadingStatus(["projects"]);

  const projectObject = useMemo(
    () => getRevenueStatusObjectFromProjectList(filteredProjects),
    [filteredProjects]
  );

  const {
    paid,
    consumed,
    total,
    paidPercentage,
    consumedSurplusPercentage,
    unclaimed,
    unclaimedPercentage,
    remainingPercentage,
    remainingValue,
  } = projectObject;

  return {
    total,
    paid,
    paidPercentage,
    consumed,
    consumedSurplusPercentage,
    remainingValue,
    remainingPercentage,
    loading,
    ref,
    filteredProjects,
    unclaimed,
    unclaimedPercentage,
  };
}
