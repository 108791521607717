import { getActiveDirectoryPeople } from "./microsoft/microsoft";
// import { microsoftMapFunction } from "./microsoft/microsoftMapFunction";
import { getBiginDeals } from "./bigin/bigin";
import {
  getMondayBoardItems,
  getMondayBoardSubItems,
  getMondayBoardItemsWithLinks,
  getMondayBoards,
  getMondayUsers,
} from "./monday/functions";
import {
  getHubSpotCompanies,
  getHubSpotDeals,
  getHubSpotDealsGroupedByPo,
  getHubSpotProducts,
} from "./hubspot";
import { getTimesheets, getInvoices } from "./timesheetPortal/functions";
import { getWorksheetData } from "./excel/functions";
// import { biginDealMapFunction } from "../../integrations/systems/bigin/biginDealMapFunction";
// import { saveUserPreferenceObject } from "./savePreferenceObject";
// import mapToDataModel from "../../common/functions/mapToDataModel";
// import { createFilterFunction } from "../../common/functions/createFilterFunction";

interface IIntegrationData {
  system: string;
  filterObject: any;
  currentTenant: string;
  systemSpecificData: any;
}

export default async function fetchIntegrationData(
  integrationData: IIntegrationData,
  infoOnly: boolean
) {
  const { system, filterObject, systemSpecificData, currentTenant } =
    integrationData;

  switch (system) {
    case "tsp":
      {
        const { operation } = systemSpecificData;
        switch (operation) {
          case "getTimesheets": {
            const rawData = await getTimesheets({
              filterObject,
              infoOnly,
              currentTenant,
              system,
            });
            return rawData;
          }
          case "getInvoices": {
            const rawData = await getInvoices({
              filterObject,
              infoOnly,
              currentTenant,
              system,
            });
            return rawData;
          }
          default:
            break;
        }
      }
      break;
    case "microsoft":
      {
        const { operation } = systemSpecificData;
        switch (operation) {
          case "getActiveDirectoryUsers":
            const rawData = await getActiveDirectoryPeople({
              filterObject,
              infoOnly,
              currentTenant,
              system,
            });
            return rawData;
          default:
            break;
        }
      }
      break;
    case "excel":
      {
        const { operation } = systemSpecificData;
        switch (operation) {
          case "getWorksheetData":
            const { driveId, fileId, worksheetId } = systemSpecificData;
            const rawData = await getWorksheetData({
              filterObject,
              infoOnly,
              currentTenant,
              system,
              driveId,
              fileId,
              worksheetId,
            });
            return rawData;
          default:
            break;
        }
      }
      break;
    case "bigin":
      {
        const { operation } = systemSpecificData;
        switch (operation) {
          case "getDeals":
            const rawData = await getBiginDeals({
              filterObject,
              infoOnly,
              currentTenant,
            });
            return rawData;
          default:
            break;
        }
      }
      break;
    case "hubspot":
      {
        const { operation } = systemSpecificData;
        switch (operation) {
          case "getDeals": {
            const rawData = await getHubSpotDeals({
              filterObject,
              infoOnly,
              currentTenant,
            });
            return rawData;
          }
          case "getDealsGroupedByPos": {
            const rawData = await getHubSpotDealsGroupedByPo({
              filterObject,
              infoOnly,
              currentTenant,
            });
            return rawData;
          }
          case "getCompanies": {
            const rawData = await getHubSpotCompanies({
              filterObject,
              infoOnly,
              currentTenant,
            });
            return rawData;
          }
          case "getProducts": {
            const rawData = await getHubSpotProducts({
              filterObject,
              infoOnly,
              currentTenant,
            });
            return rawData;
          }
          default:
            break;
        }
      }
      break;
    case "monday": {
      const { operation } = systemSpecificData;
      switch (operation) {
        case "getBoardItems": {
          const { boardId } = systemSpecificData;
          const rawData = await getMondayBoardItems({
            filterObject,
            infoOnly,
            currentTenant,
            boardId,
          });
          return rawData;
        }
        case "getBoardItemsWithLinks": {
          const { boardId } = systemSpecificData;
          const rawData = await getMondayBoardItemsWithLinks({
            filterObject,
            infoOnly,
            currentTenant,
            boardId,
          });
          return rawData;
        }
        case "getBoardSubItems": {
          const { boardId } = systemSpecificData;
          const rawData = await getMondayBoardSubItems({
            filterObject,
            infoOnly,
            currentTenant,
            boardId,
          });
          return rawData;
        }
        case "getBoards": {
          const { workspaceId } = systemSpecificData;
          const rawData = await getMondayBoards({
            filterObject,
            infoOnly,
            currentTenant,
            workspaceId,
          });
          return rawData;
        }
        case "getUsers": {
          const rawData = await getMondayUsers({
            filterObject,
            infoOnly,
            currentTenant,
          });
          return rawData;
        }
        default:
          break;
      }
    }
  }
}
