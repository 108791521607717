import { useContext } from "react";
import CrudScreen from "../common/CrudScreen";
import AppContext from "../../context/AppContext";
import { programmesSchema } from "../../context/dataSchema";
import {
  createProgramme,
  updateProgramme,
  deleteProgramme,
} from "../../graphql/mutations";

export default function Programmes() {
  const { dataObject } = useContext(AppContext);
  const { programmes: data = [] } = dataObject;

  const screenProps = {
    data: data,
    schema: programmesSchema,
    title: "Programmes",
    quickStats: null,
    tableColumns: ["account", "name"],
    filterFields: [],
    recordName: "Programme",
    pagePath: "programmes",
    dataObjectKey: "programmes",
    formProps: {
      createQuery: createProgramme,
      updateQuery: updateProgramme,
      deleteQuery: deleteProgramme,
      dataObjectKey: "programmes",
    },
    modulesToLoad: ["Accounts", "Verticals", "Programmes"],
  };
  return <CrudScreen {...screenProps} />;
}
