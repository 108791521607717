/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getSystemPreference = /* GraphQL */ `query GetSystemPreference($ownerEmail: String!) {
  getSystemPreference(ownerEmail: $ownerEmail) {
    ownerEmail
    tenantAccessString
    preferenceObject
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSystemPreferenceQueryVariables,
  APITypes.GetSystemPreferenceQuery
>;
export const listSystemPreferences = /* GraphQL */ `query ListSystemPreferences(
  $ownerEmail: String
  $filter: ModelSystemPreferenceFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listSystemPreferences(
    ownerEmail: $ownerEmail
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      ownerEmail
      tenantAccessString
      preferenceObject
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSystemPreferencesQueryVariables,
  APITypes.ListSystemPreferencesQuery
>;
export const getUserType = /* GraphQL */ `query GetUserType($id: ID!) {
  getUserType(id: $id) {
    id
    name
    tenantAccessString
    permissionsObject
    audit
    period
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserTypeQueryVariables,
  APITypes.GetUserTypeQuery
>;
export const listUserTypes = /* GraphQL */ `query ListUserTypes(
  $filter: ModelUserTypeFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      tenantAccessString
      permissionsObject
      audit
      period
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserTypesQueryVariables,
  APITypes.ListUserTypesQuery
>;
export const getUserPreference = /* GraphQL */ `query GetUserPreference($ownerEmail: String!) {
  getUserPreference(ownerEmail: $ownerEmail) {
    ownerEmail
    preferenceObject
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserPreferenceQueryVariables,
  APITypes.GetUserPreferenceQuery
>;
export const listUserPreferences = /* GraphQL */ `query ListUserPreferences(
  $ownerEmail: String
  $filter: ModelUserPreferenceFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUserPreferences(
    ownerEmail: $ownerEmail
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      ownerEmail
      preferenceObject
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserPreferencesQueryVariables,
  APITypes.ListUserPreferencesQuery
>;
export const getIntegration = /* GraphQL */ `query GetIntegration($id: ID!) {
  getIntegration(id: $id) {
    id
    module
    name
    active
    status
    dataLastUpdated
    system
    tenantAccessString
    tenantUserAccessString
    dataObject
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetIntegrationQueryVariables,
  APITypes.GetIntegrationQuery
>;
export const listIntegrations = /* GraphQL */ `query ListIntegrations(
  $filter: ModelIntegrationFilterInput
  $limit: Int
  $nextToken: String
) {
  listIntegrations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      module
      name
      active
      status
      dataLastUpdated
      system
      tenantAccessString
      tenantUserAccessString
      dataObject
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListIntegrationsQueryVariables,
  APITypes.ListIntegrationsQuery
>;
export const getDashboard = /* GraphQL */ `query GetDashboard($id: ID!) {
  getDashboard(id: $id) {
    id
    name
    order
    tenantAccessString
    userTypeAccessStrings
    configuration
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDashboardQueryVariables,
  APITypes.GetDashboardQuery
>;
export const listDashboards = /* GraphQL */ `query ListDashboards(
  $filter: ModelDashboardFilterInput
  $limit: Int
  $nextToken: String
) {
  listDashboards(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      order
      tenantAccessString
      userTypeAccessStrings
      configuration
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDashboardsQueryVariables,
  APITypes.ListDashboardsQuery
>;
export const getTenant = /* GraphQL */ `query GetTenant($id: ID!) {
  getTenant(id: $id) {
    id
    tenantAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    name
    active
    stages {
      nextToken
      __typename
    }
    verticals {
      nextToken
      __typename
    }
    programmes {
      nextToken
      __typename
    }
    accounts {
      nextToken
      __typename
    }
    roleTypes {
      nextToken
      __typename
    }
    people {
      nextToken
      __typename
    }
    customDatasets {
      nextToken
      __typename
    }
    periods {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTenantQueryVariables, APITypes.GetTenantQuery>;
export const listTenants = /* GraphQL */ `query ListTenants(
  $filter: ModelTenantFilterInput
  $limit: Int
  $nextToken: String
) {
  listTenants(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      active
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTenantsQueryVariables,
  APITypes.ListTenantsQuery
>;
export const getPeriod = /* GraphQL */ `query GetPeriod($id: ID!) {
  getPeriod(id: $id) {
    id
    tenantAccessString
    name
    period
    audit
    tenant {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      active
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    tenantPeriodsId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetPeriodQueryVariables, APITypes.GetPeriodQuery>;
export const listPeriods = /* GraphQL */ `query ListPeriods(
  $filter: ModelPeriodFilterInput
  $limit: Int
  $nextToken: String
) {
  listPeriods(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tenantAccessString
      name
      period
      audit
      createdAt
      updatedAt
      tenantPeriodsId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPeriodsQueryVariables,
  APITypes.ListPeriodsQuery
>;
export const getVertical = /* GraphQL */ `query GetVertical($id: ID!) {
  getVertical(id: $id) {
    id
    tenantAccessString
    verticalAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    name
    tenant {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      active
      createdAt
      updatedAt
      __typename
    }
    programmes {
      nextToken
      __typename
    }
    pipelines {
      nextToken
      __typename
    }
    risks {
      nextToken
      __typename
    }
    targets {
      nextToken
      __typename
    }
    projects {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    tenantVerticalsId
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetVerticalQueryVariables,
  APITypes.GetVerticalQuery
>;
export const listVerticals = /* GraphQL */ `query ListVerticals(
  $filter: ModelVerticalFilterInput
  $limit: Int
  $nextToken: String
) {
  listVerticals(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tenantAccessString
      verticalAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantVerticalsId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVerticalsQueryVariables,
  APITypes.ListVerticalsQuery
>;
export const getAccount = /* GraphQL */ `query GetAccount($id: ID!) {
  getAccount(id: $id) {
    id
    tenantAccessString
    accountAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    name
    tenant {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      active
      createdAt
      updatedAt
      __typename
    }
    programmes {
      nextToken
      __typename
    }
    pipelines {
      nextToken
      __typename
    }
    risks {
      nextToken
      __typename
    }
    projects {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    tenantAccountsId
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAccountQueryVariables,
  APITypes.GetAccountQuery
>;
export const listAccounts = /* GraphQL */ `query ListAccounts(
  $filter: ModelAccountFilterInput
  $limit: Int
  $nextToken: String
) {
  listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tenantAccessString
      accountAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantAccountsId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAccountsQueryVariables,
  APITypes.ListAccountsQuery
>;
export const getProgramme = /* GraphQL */ `query GetProgramme($id: ID!) {
  getProgramme(id: $id) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    name
    account {
      id
      tenantAccessString
      accountAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantAccountsId
      owner
      __typename
    }
    vertical {
      id
      tenantAccessString
      verticalAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantVerticalsId
      owner
      __typename
    }
    tenant {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      active
      createdAt
      updatedAt
      __typename
    }
    projects {
      nextToken
      __typename
    }
    csats {
      nextToken
      __typename
    }
    risks {
      nextToken
      __typename
    }
    weeklyUpdates {
      nextToken
      __typename
    }
    mrrs {
      nextToken
      __typename
    }
    costs {
      nextToken
      __typename
    }
    pls {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    tenantProgrammesId
    verticalProgrammesId
    accountProgrammesId
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProgrammeQueryVariables,
  APITypes.GetProgrammeQuery
>;
export const listProgrammes = /* GraphQL */ `query ListProgrammes(
  $filter: ModelProgrammeFilterInput
  $limit: Int
  $nextToken: String
) {
  listProgrammes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantProgrammesId
      verticalProgrammesId
      accountProgrammesId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProgrammesQueryVariables,
  APITypes.ListProgrammesQuery
>;
export const getTarget = /* GraphQL */ `query GetTarget($id: ID!) {
  getTarget(id: $id) {
    id
    vertical {
      id
      tenantAccessString
      verticalAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantVerticalsId
      owner
      __typename
    }
    tenantAccessString
    verticalAccessString
    programmeAccessString
    projectAccessString
    startDate
    endDate
    module
    field
    value
    period
    audit
    dataSource
    system
    integrationSystemId
    integrationId
    createdAt
    updatedAt
    verticalTargetsId
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTargetQueryVariables, APITypes.GetTargetQuery>;
export const listTargets = /* GraphQL */ `query ListTargets(
  $filter: ModelTargetFilterInput
  $limit: Int
  $nextToken: String
) {
  listTargets(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tenantAccessString
      verticalAccessString
      programmeAccessString
      projectAccessString
      startDate
      endDate
      module
      field
      value
      period
      audit
      dataSource
      system
      integrationSystemId
      integrationId
      createdAt
      updatedAt
      verticalTargetsId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTargetsQueryVariables,
  APITypes.ListTargetsQuery
>;
export const getProject = /* GraphQL */ `query GetProject($id: ID!) {
  getProject(id: $id) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    deliveryManagers {
      nextToken
      __typename
    }
    projectManagers {
      nextToken
      __typename
    }
    name
    tcv
    margin
    workstream
    currency
    budget
    spend
    country
    status
    startDate
    endDate
    revenueSold
    costSold
    revenueActual
    costActual
    consumed
    region {
      id
      continent
      subregion
      name
      createdAt
      updatedAt
      __typename
    }
    programme {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantProgrammesId
      verticalProgrammesId
      accountProgrammesId
      owner
      __typename
    }
    account {
      id
      tenantAccessString
      accountAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantAccountsId
      owner
      __typename
    }
    vertical {
      id
      tenantAccessString
      verticalAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantVerticalsId
      owner
      __typename
    }
    tasks {
      nextToken
      __typename
    }
    statements {
      nextToken
      __typename
    }
    projectRoles {
      nextToken
      __typename
    }
    risks {
      nextToken
      __typename
    }
    csats {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    verticalProjectsId
    accountProjectsId
    programmeProjectsId
    regionProjectsId
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProjectQueryVariables,
  APITypes.GetProjectQuery
>;
export const listProjects = /* GraphQL */ `query ListProjects(
  $filter: ModelProjectFilterInput
  $limit: Int
  $nextToken: String
) {
  listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      tcv
      margin
      workstream
      currency
      budget
      spend
      country
      status
      startDate
      endDate
      revenueSold
      costSold
      revenueActual
      costActual
      consumed
      createdAt
      updatedAt
      verticalProjectsId
      accountProjectsId
      programmeProjectsId
      regionProjectsId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProjectsQueryVariables,
  APITypes.ListProjectsQuery
>;
export const getRisk = /* GraphQL */ `query GetRisk($id: ID!) {
  getRisk(id: $id) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    name
    description
    actionOwner
    status
    impactDate
    cause
    effect
    impactDescription
    impactType
    riskResponse
    actionMitigation
    targetResolutionDate
    probability
    impact
    estimatedImpactCost
    escalatedToPortfolio
    detailedUpdate
    dateRaised
    dateOfNextReview
    riskOwners {
      nextToken
      __typename
    }
    vertical {
      id
      tenantAccessString
      verticalAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantVerticalsId
      owner
      __typename
    }
    account {
      id
      tenantAccessString
      accountAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantAccountsId
      owner
      __typename
    }
    programme {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantProgrammesId
      verticalProgrammesId
      accountProgrammesId
      owner
      __typename
    }
    project {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      tcv
      margin
      workstream
      currency
      budget
      spend
      country
      status
      startDate
      endDate
      revenueSold
      costSold
      revenueActual
      costActual
      consumed
      createdAt
      updatedAt
      verticalProjectsId
      accountProjectsId
      programmeProjectsId
      regionProjectsId
      owner
      __typename
    }
    createdAt
    updatedAt
    verticalRisksId
    accountRisksId
    programmeRisksId
    projectRisksId
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetRiskQueryVariables, APITypes.GetRiskQuery>;
export const listRisks = /* GraphQL */ `query ListRisks(
  $filter: ModelRiskFilterInput
  $limit: Int
  $nextToken: String
) {
  listRisks(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      description
      actionOwner
      status
      impactDate
      cause
      effect
      impactDescription
      impactType
      riskResponse
      actionMitigation
      targetResolutionDate
      probability
      impact
      estimatedImpactCost
      escalatedToPortfolio
      detailedUpdate
      dateRaised
      dateOfNextReview
      createdAt
      updatedAt
      verticalRisksId
      accountRisksId
      programmeRisksId
      projectRisksId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListRisksQueryVariables, APITypes.ListRisksQuery>;
export const getTask = /* GraphQL */ `query GetTask($id: ID!) {
  getTask(id: $id) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    name
    complete
    category
    type
    status
    startDate
    endDate
    completedDate
    assignees {
      nextToken
      __typename
    }
    project {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      tcv
      margin
      workstream
      currency
      budget
      spend
      country
      status
      startDate
      endDate
      revenueSold
      costSold
      revenueActual
      costActual
      consumed
      createdAt
      updatedAt
      verticalProjectsId
      accountProjectsId
      programmeProjectsId
      regionProjectsId
      owner
      __typename
    }
    createdAt
    updatedAt
    projectTasksId
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTaskQueryVariables, APITypes.GetTaskQuery>;
export const listTasks = /* GraphQL */ `query ListTasks(
  $filter: ModelTaskFilterInput
  $limit: Int
  $nextToken: String
) {
  listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      complete
      category
      type
      status
      startDate
      endDate
      completedDate
      createdAt
      updatedAt
      projectTasksId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListTasksQueryVariables, APITypes.ListTasksQuery>;
export const getCsat = /* GraphQL */ `query GetCsat($id: ID!) {
  getCsat(id: $id) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    score
    comments
    date
    programme {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantProgrammesId
      verticalProgrammesId
      accountProgrammesId
      owner
      __typename
    }
    project {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      tcv
      margin
      workstream
      currency
      budget
      spend
      country
      status
      startDate
      endDate
      revenueSold
      costSold
      revenueActual
      costActual
      consumed
      createdAt
      updatedAt
      verticalProjectsId
      accountProjectsId
      programmeProjectsId
      regionProjectsId
      owner
      __typename
    }
    createdAt
    updatedAt
    programmeCsatsId
    projectCsatsId
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetCsatQueryVariables, APITypes.GetCsatQuery>;
export const listCsats = /* GraphQL */ `query ListCsats(
  $filter: ModelCsatFilterInput
  $limit: Int
  $nextToken: String
) {
  listCsats(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      score
      comments
      date
      createdAt
      updatedAt
      programmeCsatsId
      projectCsatsId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListCsatsQueryVariables, APITypes.ListCsatsQuery>;
export const getStage = /* GraphQL */ `query GetStage($id: ID!) {
  getStage(id: $id) {
    id
    tenant {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      active
      createdAt
      updatedAt
      __typename
    }
    tenantAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    order
    probability
    name
    pipelines {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    tenantStagesId
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetStageQueryVariables, APITypes.GetStageQuery>;
export const listStages = /* GraphQL */ `query ListStages(
  $filter: ModelStageFilterInput
  $limit: Int
  $nextToken: String
) {
  listStages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      order
      probability
      name
      createdAt
      updatedAt
      tenantStagesId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStagesQueryVariables,
  APITypes.ListStagesQuery
>;
export const getPipeline = /* GraphQL */ `query GetPipeline($id: ID!) {
  getPipeline(id: $id) {
    id
    tenantAccessString
    accountAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    amount
    closeDate
    closedNotes
    closingDate
    currency
    dealName
    description
    grossMarginLength
    grossMarginPerMonth
    incomeType
    intro
    managingPartner
    numberOfDeliveryDays
    opportunityLead
    pipeline
    serviceGoLiveDate
    sourceOfferingOpportunity
    totalGrossProfitValue
    totalRevenueValue
    typeOfSale
    stage {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      order
      probability
      name
      createdAt
      updatedAt
      tenantStagesId
      owner
      __typename
    }
    vertical {
      id
      tenantAccessString
      verticalAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantVerticalsId
      owner
      __typename
    }
    account {
      id
      tenantAccessString
      accountAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantAccountsId
      owner
      __typename
    }
    sowRecords {
      nextToken
      __typename
    }
    projectRoles {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    verticalPipelinesId
    accountPipelinesId
    stagePipelinesId
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPipelineQueryVariables,
  APITypes.GetPipelineQuery
>;
export const listPipelines = /* GraphQL */ `query ListPipelines(
  $filter: ModelPipelineFilterInput
  $limit: Int
  $nextToken: String
) {
  listPipelines(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tenantAccessString
      accountAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      amount
      closeDate
      closedNotes
      closingDate
      currency
      dealName
      description
      grossMarginLength
      grossMarginPerMonth
      incomeType
      intro
      managingPartner
      numberOfDeliveryDays
      opportunityLead
      pipeline
      serviceGoLiveDate
      sourceOfferingOpportunity
      totalGrossProfitValue
      totalRevenueValue
      typeOfSale
      createdAt
      updatedAt
      verticalPipelinesId
      accountPipelinesId
      stagePipelinesId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPipelinesQueryVariables,
  APITypes.ListPipelinesQuery
>;
export const getStatement = /* GraphQL */ `query GetStatement($id: ID!) {
  getStatement(id: $id) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    agreement
    description
    date
    contractType
    days
    durationDays
    location
    valueCredits
    valueUsd
    consumed
    workPackages
    paid
    stage
    completedDate
    project {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      tcv
      margin
      workstream
      currency
      budget
      spend
      country
      status
      startDate
      endDate
      revenueSold
      costSold
      revenueActual
      costActual
      consumed
      createdAt
      updatedAt
      verticalProjectsId
      accountProjectsId
      programmeProjectsId
      regionProjectsId
      owner
      __typename
    }
    pipeline {
      id
      tenantAccessString
      accountAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      amount
      closeDate
      closedNotes
      closingDate
      currency
      dealName
      description
      grossMarginLength
      grossMarginPerMonth
      incomeType
      intro
      managingPartner
      numberOfDeliveryDays
      opportunityLead
      pipeline
      serviceGoLiveDate
      sourceOfferingOpportunity
      totalGrossProfitValue
      totalRevenueValue
      typeOfSale
      createdAt
      updatedAt
      verticalPipelinesId
      accountPipelinesId
      stagePipelinesId
      owner
      __typename
    }
    deliverables {
      nextToken
      __typename
    }
    invoices {
      nextToken
      __typename
    }
    changes {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    projectStatementsId
    pipelineSowRecordsId
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStatementQueryVariables,
  APITypes.GetStatementQuery
>;
export const listStatements = /* GraphQL */ `query ListStatements(
  $filter: ModelStatementFilterInput
  $limit: Int
  $nextToken: String
) {
  listStatements(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      agreement
      description
      date
      contractType
      days
      durationDays
      location
      valueCredits
      valueUsd
      consumed
      workPackages
      paid
      stage
      completedDate
      createdAt
      updatedAt
      projectStatementsId
      pipelineSowRecordsId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStatementsQueryVariables,
  APITypes.ListStatementsQuery
>;
export const getDeliverable = /* GraphQL */ `query GetDeliverable($id: ID!) {
  getDeliverable(id: $id) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    artifact
    deliverableOwner
    progress
    startDate
    dueDate
    eta
    actualDate
    comments
    sow {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      agreement
      description
      date
      contractType
      days
      durationDays
      location
      valueCredits
      valueUsd
      consumed
      workPackages
      paid
      stage
      completedDate
      createdAt
      updatedAt
      projectStatementsId
      pipelineSowRecordsId
      owner
      __typename
    }
    createdAt
    updatedAt
    statementDeliverablesId
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDeliverableQueryVariables,
  APITypes.GetDeliverableQuery
>;
export const listDeliverables = /* GraphQL */ `query ListDeliverables(
  $filter: ModelDeliverableFilterInput
  $limit: Int
  $nextToken: String
) {
  listDeliverables(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      artifact
      deliverableOwner
      progress
      startDate
      dueDate
      eta
      actualDate
      comments
      createdAt
      updatedAt
      statementDeliverablesId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDeliverablesQueryVariables,
  APITypes.ListDeliverablesQuery
>;
export const getInvoice = /* GraphQL */ `query GetInvoice($id: ID!) {
  getInvoice(id: $id) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    invoiceNumber
    amount
    invoiceDate
    reference
    billingAddress
    billingEmail
    VATRegNumber
    supplierNumber
    supplierAddress
    description
    unitPrice
    quantity
    taxAmount
    totalAmount
    dueDate
    paymentTerms
    bankName
    accountName
    accountNumber
    sortCode
    routingNumber
    paid
    sow {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      agreement
      description
      date
      contractType
      days
      durationDays
      location
      valueCredits
      valueUsd
      consumed
      workPackages
      paid
      stage
      completedDate
      createdAt
      updatedAt
      projectStatementsId
      pipelineSowRecordsId
      owner
      __typename
    }
    timesheet {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      timesheetRef
      unit
      unitAmount
      unitRate
      unitCost
      startDate
      endDate
      timeSheetData
      createdAt
      updatedAt
      personTimesheetsId
      projectRoleTimesheetsId
      owner
      __typename
    }
    createdAt
    updatedAt
    statementInvoicesId
    timesheetInvoicesId
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInvoiceQueryVariables,
  APITypes.GetInvoiceQuery
>;
export const listInvoices = /* GraphQL */ `query ListInvoices(
  $filter: ModelInvoiceFilterInput
  $limit: Int
  $nextToken: String
) {
  listInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      invoiceNumber
      amount
      invoiceDate
      reference
      billingAddress
      billingEmail
      VATRegNumber
      supplierNumber
      supplierAddress
      description
      unitPrice
      quantity
      taxAmount
      totalAmount
      dueDate
      paymentTerms
      bankName
      accountName
      accountNumber
      sortCode
      routingNumber
      paid
      createdAt
      updatedAt
      statementInvoicesId
      timesheetInvoicesId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInvoicesQueryVariables,
  APITypes.ListInvoicesQuery
>;
export const getRegion = /* GraphQL */ `query GetRegion($id: ID!) {
  getRegion(id: $id) {
    id
    continent
    subregion
    name
    projects {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetRegionQueryVariables, APITypes.GetRegionQuery>;
export const listRegions = /* GraphQL */ `query ListRegions(
  $filter: ModelRegionFilterInput
  $limit: Int
  $nextToken: String
) {
  listRegions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      continent
      subregion
      name
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRegionsQueryVariables,
  APITypes.ListRegionsQuery
>;
export const getRoleType = /* GraphQL */ `query GetRoleType($id: ID!) {
  getRoleType(id: $id) {
    id
    tenantAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    name
    tenant {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      active
      createdAt
      updatedAt
      __typename
    }
    people {
      nextToken
      __typename
    }
    projectRoles {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    tenantRoleTypesId
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRoleTypeQueryVariables,
  APITypes.GetRoleTypeQuery
>;
export const listRoleTypes = /* GraphQL */ `query ListRoleTypes(
  $filter: ModelRoleTypeFilterInput
  $limit: Int
  $nextToken: String
) {
  listRoleTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantRoleTypesId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRoleTypesQueryVariables,
  APITypes.ListRoleTypesQuery
>;
export const getPerson = /* GraphQL */ `query GetPerson($id: ID!) {
  getPerson(id: $id) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    firstName
    lastName
    email
    position
    location
    hoursPerWeek
    currentlyActive
    skills
    tenant {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      active
      createdAt
      updatedAt
      __typename
    }
    employeeSatisfactionRecords {
      nextToken
      __typename
    }
    timesheets {
      nextToken
      __typename
    }
    projectAllocations {
      nextToken
      __typename
    }
    annualLeave {
      nextToken
      __typename
    }
    roleTypes {
      nextToken
      __typename
    }
    risks {
      nextToken
      __typename
    }
    tasks {
      nextToken
      __typename
    }
    projectsDeliveryManaged {
      nextToken
      __typename
    }
    projectsProjectManaged {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    tenantPeopleId
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetPersonQueryVariables, APITypes.GetPersonQuery>;
export const listPeople = /* GraphQL */ `query ListPeople(
  $filter: ModelPersonFilterInput
  $limit: Int
  $nextToken: String
) {
  listPeople(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      firstName
      lastName
      email
      position
      location
      hoursPerWeek
      currentlyActive
      skills
      createdAt
      updatedAt
      tenantPeopleId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPeopleQueryVariables,
  APITypes.ListPeopleQuery
>;
export const getAnnualLeave = /* GraphQL */ `query GetAnnualLeave($id: ID!) {
  getAnnualLeave(id: $id) {
    id
    person {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      firstName
      lastName
      email
      position
      location
      hoursPerWeek
      currentlyActive
      skills
      createdAt
      updatedAt
      tenantPeopleId
      owner
      __typename
    }
    tenantAccessString
    startDate
    endDate
    numberOfDays
    status
    period
    audit
    dataSource
    system
    integrationSystemId
    integrationId
    createdAt
    updatedAt
    personAnnualLeaveId
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAnnualLeaveQueryVariables,
  APITypes.GetAnnualLeaveQuery
>;
export const listAnnualLeaves = /* GraphQL */ `query ListAnnualLeaves(
  $filter: ModelAnnualLeaveFilterInput
  $limit: Int
  $nextToken: String
) {
  listAnnualLeaves(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tenantAccessString
      startDate
      endDate
      numberOfDays
      status
      period
      audit
      dataSource
      system
      integrationSystemId
      integrationId
      createdAt
      updatedAt
      personAnnualLeaveId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAnnualLeavesQueryVariables,
  APITypes.ListAnnualLeavesQuery
>;
export const getProjectRole = /* GraphQL */ `query GetProjectRole($id: ID!) {
  getProjectRole(id: $id) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    fte
    description
    unit
    plannedUnits
    actualUnits
    unitRate
    unitCost
    billingFrequency
    startDate
    endDate
    status
    project {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      tcv
      margin
      workstream
      currency
      budget
      spend
      country
      status
      startDate
      endDate
      revenueSold
      costSold
      revenueActual
      costActual
      consumed
      createdAt
      updatedAt
      verticalProjectsId
      accountProjectsId
      programmeProjectsId
      regionProjectsId
      owner
      __typename
    }
    pipeline {
      id
      tenantAccessString
      accountAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      amount
      closeDate
      closedNotes
      closingDate
      currency
      dealName
      description
      grossMarginLength
      grossMarginPerMonth
      incomeType
      intro
      managingPartner
      numberOfDeliveryDays
      opportunityLead
      pipeline
      serviceGoLiveDate
      sourceOfferingOpportunity
      totalGrossProfitValue
      totalRevenueValue
      typeOfSale
      createdAt
      updatedAt
      verticalPipelinesId
      accountPipelinesId
      stagePipelinesId
      owner
      __typename
    }
    projectAllocations {
      nextToken
      __typename
    }
    roleTypes {
      nextToken
      __typename
    }
    timesheets {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    projectProjectRolesId
    pipelineProjectRolesId
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProjectRoleQueryVariables,
  APITypes.GetProjectRoleQuery
>;
export const listProjectRoles = /* GraphQL */ `query ListProjectRoles(
  $filter: ModelProjectRoleFilterInput
  $limit: Int
  $nextToken: String
) {
  listProjectRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      fte
      description
      unit
      plannedUnits
      actualUnits
      unitRate
      unitCost
      billingFrequency
      startDate
      endDate
      status
      createdAt
      updatedAt
      projectProjectRolesId
      pipelineProjectRolesId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProjectRolesQueryVariables,
  APITypes.ListProjectRolesQuery
>;
export const getProjectAllocation = /* GraphQL */ `query GetProjectAllocation($id: ID!) {
  getProjectAllocation(id: $id) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    startDate
    endDate
    person {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      firstName
      lastName
      email
      position
      location
      hoursPerWeek
      currentlyActive
      skills
      createdAt
      updatedAt
      tenantPeopleId
      owner
      __typename
    }
    projectRole {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      fte
      description
      unit
      plannedUnits
      actualUnits
      unitRate
      unitCost
      billingFrequency
      startDate
      endDate
      status
      createdAt
      updatedAt
      projectProjectRolesId
      pipelineProjectRolesId
      owner
      __typename
    }
    createdAt
    updatedAt
    personProjectAllocationsId
    projectRoleProjectAllocationsId
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProjectAllocationQueryVariables,
  APITypes.GetProjectAllocationQuery
>;
export const listProjectAllocations = /* GraphQL */ `query ListProjectAllocations(
  $filter: ModelProjectAllocationFilterInput
  $limit: Int
  $nextToken: String
) {
  listProjectAllocations(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      startDate
      endDate
      createdAt
      updatedAt
      personProjectAllocationsId
      projectRoleProjectAllocationsId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProjectAllocationsQueryVariables,
  APITypes.ListProjectAllocationsQuery
>;
export const getTimesheet = /* GraphQL */ `query GetTimesheet($id: ID!) {
  getTimesheet(id: $id) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    timesheetRef
    unit
    unitAmount
    unitRate
    unitCost
    startDate
    endDate
    timeSheetData
    person {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      firstName
      lastName
      email
      position
      location
      hoursPerWeek
      currentlyActive
      skills
      createdAt
      updatedAt
      tenantPeopleId
      owner
      __typename
    }
    projectRole {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      fte
      description
      unit
      plannedUnits
      actualUnits
      unitRate
      unitCost
      billingFrequency
      startDate
      endDate
      status
      createdAt
      updatedAt
      projectProjectRolesId
      pipelineProjectRolesId
      owner
      __typename
    }
    invoices {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    personTimesheetsId
    projectRoleTimesheetsId
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTimesheetQueryVariables,
  APITypes.GetTimesheetQuery
>;
export const listTimesheets = /* GraphQL */ `query ListTimesheets(
  $filter: ModelTimesheetFilterInput
  $limit: Int
  $nextToken: String
) {
  listTimesheets(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      timesheetRef
      unit
      unitAmount
      unitRate
      unitCost
      startDate
      endDate
      timeSheetData
      createdAt
      updatedAt
      personTimesheetsId
      projectRoleTimesheetsId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTimesheetsQueryVariables,
  APITypes.ListTimesheetsQuery
>;
export const getEmployeeSatisfaction = /* GraphQL */ `query GetEmployeeSatisfaction($id: ID!) {
  getEmployeeSatisfaction(id: $id) {
    id
    tenantAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    date
    score
    comment
    person {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      firstName
      lastName
      email
      position
      location
      hoursPerWeek
      currentlyActive
      skills
      createdAt
      updatedAt
      tenantPeopleId
      owner
      __typename
    }
    createdAt
    updatedAt
    personEmployeeSatisfactionRecordsId
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEmployeeSatisfactionQueryVariables,
  APITypes.GetEmployeeSatisfactionQuery
>;
export const listEmployeeSatisfactions = /* GraphQL */ `query ListEmployeeSatisfactions(
  $filter: ModelEmployeeSatisfactionFilterInput
  $limit: Int
  $nextToken: String
) {
  listEmployeeSatisfactions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      date
      score
      comment
      createdAt
      updatedAt
      personEmployeeSatisfactionRecordsId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEmployeeSatisfactionsQueryVariables,
  APITypes.ListEmployeeSatisfactionsQuery
>;
export const getWeeklyUpdate = /* GraphQL */ `query GetWeeklyUpdate($id: ID!) {
  getWeeklyUpdate(id: $id) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    programme {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantProgrammesId
      verticalProgrammesId
      accountProgrammesId
      owner
      __typename
    }
    score
    comments
    date
    audit
    createdAt
    updatedAt
    programmeWeeklyUpdatesId
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetWeeklyUpdateQueryVariables,
  APITypes.GetWeeklyUpdateQuery
>;
export const listWeeklyUpdates = /* GraphQL */ `query ListWeeklyUpdates(
  $filter: ModelWeeklyUpdateFilterInput
  $limit: Int
  $nextToken: String
) {
  listWeeklyUpdates(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      score
      comments
      date
      audit
      createdAt
      updatedAt
      programmeWeeklyUpdatesId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListWeeklyUpdatesQueryVariables,
  APITypes.ListWeeklyUpdatesQuery
>;
export const getCustomDataset = /* GraphQL */ `query GetCustomDataset($id: ID!) {
  getCustomDataset(id: $id) {
    id
    tenantAccessString
    period
    name
    tenant {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      active
      createdAt
      updatedAt
      __typename
    }
    schema
    permissionsConfig
    customRecords {
      nextToken
      __typename
    }
    audit
    createdAt
    updatedAt
    tenantCustomDatasetsId
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCustomDatasetQueryVariables,
  APITypes.GetCustomDatasetQuery
>;
export const listCustomDatasets = /* GraphQL */ `query ListCustomDatasets(
  $filter: ModelCustomDatasetFilterInput
  $limit: Int
  $nextToken: String
) {
  listCustomDatasets(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tenantAccessString
      period
      name
      schema
      permissionsConfig
      audit
      createdAt
      updatedAt
      tenantCustomDatasetsId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCustomDatasetsQueryVariables,
  APITypes.ListCustomDatasetsQuery
>;
export const getChange = /* GraphQL */ `query GetChange($id: ID!) {
  getChange(id: $id) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    statementOfWork {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      agreement
      description
      date
      contractType
      days
      durationDays
      location
      valueCredits
      valueUsd
      consumed
      workPackages
      paid
      stage
      completedDate
      createdAt
      updatedAt
      projectStatementsId
      pipelineSowRecordsId
      owner
      __typename
    }
    title
    requesterName
    requesterEmail
    organization
    requestType
    requestTitle
    changeRequestArea
    programName
    requestCategory
    requestDetail
    priority
    effectiveDate
    impactDeltaDays
    requestTypeLocation
    financialImpact
    emergencyRequest
    deliveryApproval
    dateDeliveryTeamApproved
    deliveryTeamApprovalNotes
    sltApproval
    sltApprovalDate
    sltApprovalNotes
    impactStatement
    additionalInfo
    newSow
    pmoAdmin
    customerNotified
    audit
    createdAt
    updatedAt
    statementChangesId
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetChangeQueryVariables, APITypes.GetChangeQuery>;
export const listChanges = /* GraphQL */ `query ListChanges(
  $filter: ModelChangeFilterInput
  $limit: Int
  $nextToken: String
) {
  listChanges(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      title
      requesterName
      requesterEmail
      organization
      requestType
      requestTitle
      changeRequestArea
      programName
      requestCategory
      requestDetail
      priority
      effectiveDate
      impactDeltaDays
      requestTypeLocation
      financialImpact
      emergencyRequest
      deliveryApproval
      dateDeliveryTeamApproved
      deliveryTeamApprovalNotes
      sltApproval
      sltApprovalDate
      sltApprovalNotes
      impactStatement
      additionalInfo
      newSow
      pmoAdmin
      customerNotified
      audit
      createdAt
      updatedAt
      statementChangesId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListChangesQueryVariables,
  APITypes.ListChangesQuery
>;
export const getMRR = /* GraphQL */ `query GetMRR($id: ID!) {
  getMRR(id: $id) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    pmAccess
    dmAccess
    programme {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantProgrammesId
      verticalProgrammesId
      accountProgrammesId
      owner
      __typename
    }
    fiscalYear
    geo
    region
    subRegion
    territory
    engagementName
    engagementId
    engagementStatus
    engagementType
    businessUnit
    billToCustomer
    endCustomer
    budgetedHoursTotal
    plannedHoursTotal
    createdOn
    paymentType
    revRecType
    billingSKU
    engagementAmount
    billingCurrency
    totalBilledRedeemed
    remainingAmount
    operationAnalysts
    timeApprover
    billedRedeemedByDateRange
    remainingForecastByDateRange
    forecastHrsThisQtr
    forecastRevenueOfCurrentQtr
    forecastRevenueOfCurrentQtrUSD
    totalBillableHoursPriorQtrEnd
    totalBillableHoursThisQtr
    mgmtRevAsOfPriorQtr
    mgmtRevAsOfPriorQtrUSD
    qtrManagementRevenueToDate
    qtrManagementRevenueToDateUSD
    internalHoursQTD
    subbingHoursQTD
    expensesBilledRedeemedByDateRange
    contractStatus
    poNumber
    billedRedeemedByDateRangeUSD
    lastTimeEntryDate
    statusDuringDateRange
    totalBilledRedeemedUSD
    engagementAmountInUSD
    totalActualHours
    hoursBasedForecastRevenueOfCurrentQtr
    hoursBasedForecastRevenueOfCurrentQtrUSD
    plannedHrsForQtr
    program
    projectProfile
    ssm
    projectManager
    engagementClosedDate
    agreementNumber
    opportunityNumber
    psoSubRegion2
    revenueRecognized
    unbilled
    audit
    period
    createdAt
    updatedAt
    programmeMrrsId
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetMRRQueryVariables, APITypes.GetMRRQuery>;
export const listMRRS = /* GraphQL */ `query ListMRRS($filter: ModelMRRFilterInput, $limit: Int, $nextToken: String) {
  listMRRS(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      pmAccess
      dmAccess
      fiscalYear
      geo
      region
      subRegion
      territory
      engagementName
      engagementId
      engagementStatus
      engagementType
      businessUnit
      billToCustomer
      endCustomer
      budgetedHoursTotal
      plannedHoursTotal
      createdOn
      paymentType
      revRecType
      billingSKU
      engagementAmount
      billingCurrency
      totalBilledRedeemed
      remainingAmount
      operationAnalysts
      timeApprover
      billedRedeemedByDateRange
      remainingForecastByDateRange
      forecastHrsThisQtr
      forecastRevenueOfCurrentQtr
      forecastRevenueOfCurrentQtrUSD
      totalBillableHoursPriorQtrEnd
      totalBillableHoursThisQtr
      mgmtRevAsOfPriorQtr
      mgmtRevAsOfPriorQtrUSD
      qtrManagementRevenueToDate
      qtrManagementRevenueToDateUSD
      internalHoursQTD
      subbingHoursQTD
      expensesBilledRedeemedByDateRange
      contractStatus
      poNumber
      billedRedeemedByDateRangeUSD
      lastTimeEntryDate
      statusDuringDateRange
      totalBilledRedeemedUSD
      engagementAmountInUSD
      totalActualHours
      hoursBasedForecastRevenueOfCurrentQtr
      hoursBasedForecastRevenueOfCurrentQtrUSD
      plannedHrsForQtr
      program
      projectProfile
      ssm
      projectManager
      engagementClosedDate
      agreementNumber
      opportunityNumber
      psoSubRegion2
      revenueRecognized
      unbilled
      audit
      period
      createdAt
      updatedAt
      programmeMrrsId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListMRRSQueryVariables, APITypes.ListMRRSQuery>;
export const getCost = /* GraphQL */ `query GetCost($id: ID!) {
  getCost(id: $id) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    pmAccess
    dmAccess
    programme {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantProgrammesId
      verticalProgrammesId
      accountProgrammesId
      owner
      __typename
    }
    geo
    region
    projectManager
    subRegion
    engagementNumber
    engagementName
    revRecType
    paymentType
    engagementStatus
    engagementCreatedOn
    engagementAmount
    engagementCurrency
    roleName
    billingRateForRole
    budgetedHours
    costRateAverageForResources
    budgetedCostForRole
    plannedHours
    actualHours
    internalPlannedCost
    internalActualCost
    partnerPlannedCostForRole
    partnerActualCostForRole
    audit
    period
    createdAt
    updatedAt
    programmeCostsId
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetCostQueryVariables, APITypes.GetCostQuery>;
export const listCosts = /* GraphQL */ `query ListCosts(
  $filter: ModelCostFilterInput
  $limit: Int
  $nextToken: String
) {
  listCosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      pmAccess
      dmAccess
      geo
      region
      projectManager
      subRegion
      engagementNumber
      engagementName
      revRecType
      paymentType
      engagementStatus
      engagementCreatedOn
      engagementAmount
      engagementCurrency
      roleName
      billingRateForRole
      budgetedHours
      costRateAverageForResources
      budgetedCostForRole
      plannedHours
      actualHours
      internalPlannedCost
      internalActualCost
      partnerPlannedCostForRole
      partnerActualCostForRole
      audit
      period
      createdAt
      updatedAt
      programmeCostsId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListCostsQueryVariables, APITypes.ListCostsQuery>;
export const getPL = /* GraphQL */ `query GetPL($id: ID!) {
  getPL(id: $id) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    pmAccess
    dmAccess
    programme {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantProgrammesId
      verticalProgrammesId
      accountProgrammesId
      owner
      __typename
    }
    engId
    engagementName
    status
    gar
    projectManager
    subcontractor
    lastInvoice
    budgetMarginType
    impactedByAllInCostChange
    deAuditMessageUSD
    billingCurrency
    budgetComments
    budgetGar
    csatComments
    csatGar
    engagementStatus
    engagementType
    fisYear2QtrName
    lastBillingEntry
    mitigationAction
    projectGarComments
    geo
    region
    subRegion
    scheduleComments
    scheduleGar
    deliveryManager
    deAuditResultsUSD
    revenueSoldUSD
    revenueRecognizedUSD
    billedRedeemedUSD
    costSoldUSD
    marginSold
    revisedCostSoldUSD
    revisedMarginSold
    percentOfHoursBeforeAllInChange
    revenueForecastExitUSD
    costForecastExitUSD
    marginForecastExit
    revenuePlannedUSD
    costPlannedUSD
    marginPlanned
    revenueActualUSD
    costActualUSD
    marginActual
    hoursSold
    hoursPlanned
    hoursActual
    percentCompleteHoursSold
    revenueSoldBC
    maxExchangeRateUsed
    billedRedeemedBC
    costForecastExitBC
    costSoldBC
    revenueForecastExitBC
    revenueRecognizedBC
    audit
    period
    createdAt
    updatedAt
    programmePlsId
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetPLQueryVariables, APITypes.GetPLQuery>;
export const listPLS = /* GraphQL */ `query ListPLS($filter: ModelPLFilterInput, $limit: Int, $nextToken: String) {
  listPLS(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      pmAccess
      dmAccess
      engId
      engagementName
      status
      gar
      projectManager
      subcontractor
      lastInvoice
      budgetMarginType
      impactedByAllInCostChange
      deAuditMessageUSD
      billingCurrency
      budgetComments
      budgetGar
      csatComments
      csatGar
      engagementStatus
      engagementType
      fisYear2QtrName
      lastBillingEntry
      mitigationAction
      projectGarComments
      geo
      region
      subRegion
      scheduleComments
      scheduleGar
      deliveryManager
      deAuditResultsUSD
      revenueSoldUSD
      revenueRecognizedUSD
      billedRedeemedUSD
      costSoldUSD
      marginSold
      revisedCostSoldUSD
      revisedMarginSold
      percentOfHoursBeforeAllInChange
      revenueForecastExitUSD
      costForecastExitUSD
      marginForecastExit
      revenuePlannedUSD
      costPlannedUSD
      marginPlanned
      revenueActualUSD
      costActualUSD
      marginActual
      hoursSold
      hoursPlanned
      hoursActual
      percentCompleteHoursSold
      revenueSoldBC
      maxExchangeRateUsed
      billedRedeemedBC
      costForecastExitBC
      costSoldBC
      revenueForecastExitBC
      revenueRecognizedBC
      audit
      period
      createdAt
      updatedAt
      programmePlsId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListPLSQueryVariables, APITypes.ListPLSQuery>;
export const getDeliveryManagerProjects = /* GraphQL */ `query GetDeliveryManagerProjects($id: ID!) {
  getDeliveryManagerProjects(id: $id) {
    id
    projectID
    personID
    project {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      tcv
      margin
      workstream
      currency
      budget
      spend
      country
      status
      startDate
      endDate
      revenueSold
      costSold
      revenueActual
      costActual
      consumed
      createdAt
      updatedAt
      verticalProjectsId
      accountProjectsId
      programmeProjectsId
      regionProjectsId
      owner
      __typename
    }
    person {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      firstName
      lastName
      email
      position
      location
      hoursPerWeek
      currentlyActive
      skills
      createdAt
      updatedAt
      tenantPeopleId
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDeliveryManagerProjectsQueryVariables,
  APITypes.GetDeliveryManagerProjectsQuery
>;
export const listDeliveryManagerProjects = /* GraphQL */ `query ListDeliveryManagerProjects(
  $filter: ModelDeliveryManagerProjectsFilterInput
  $limit: Int
  $nextToken: String
) {
  listDeliveryManagerProjects(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      projectID
      personID
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDeliveryManagerProjectsQueryVariables,
  APITypes.ListDeliveryManagerProjectsQuery
>;
export const getProjectManagerProjects = /* GraphQL */ `query GetProjectManagerProjects($id: ID!) {
  getProjectManagerProjects(id: $id) {
    id
    projectID
    personID
    project {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      tcv
      margin
      workstream
      currency
      budget
      spend
      country
      status
      startDate
      endDate
      revenueSold
      costSold
      revenueActual
      costActual
      consumed
      createdAt
      updatedAt
      verticalProjectsId
      accountProjectsId
      programmeProjectsId
      regionProjectsId
      owner
      __typename
    }
    person {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      firstName
      lastName
      email
      position
      location
      hoursPerWeek
      currentlyActive
      skills
      createdAt
      updatedAt
      tenantPeopleId
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProjectManagerProjectsQueryVariables,
  APITypes.GetProjectManagerProjectsQuery
>;
export const listProjectManagerProjects = /* GraphQL */ `query ListProjectManagerProjects(
  $filter: ModelProjectManagerProjectsFilterInput
  $limit: Int
  $nextToken: String
) {
  listProjectManagerProjects(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      projectID
      personID
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProjectManagerProjectsQueryVariables,
  APITypes.ListProjectManagerProjectsQuery
>;
export const getPersonRisks = /* GraphQL */ `query GetPersonRisks($id: ID!) {
  getPersonRisks(id: $id) {
    id
    riskID
    personID
    risk {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      description
      actionOwner
      status
      impactDate
      cause
      effect
      impactDescription
      impactType
      riskResponse
      actionMitigation
      targetResolutionDate
      probability
      impact
      estimatedImpactCost
      escalatedToPortfolio
      detailedUpdate
      dateRaised
      dateOfNextReview
      createdAt
      updatedAt
      verticalRisksId
      accountRisksId
      programmeRisksId
      projectRisksId
      owner
      __typename
    }
    person {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      firstName
      lastName
      email
      position
      location
      hoursPerWeek
      currentlyActive
      skills
      createdAt
      updatedAt
      tenantPeopleId
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPersonRisksQueryVariables,
  APITypes.GetPersonRisksQuery
>;
export const listPersonRisks = /* GraphQL */ `query ListPersonRisks(
  $filter: ModelPersonRisksFilterInput
  $limit: Int
  $nextToken: String
) {
  listPersonRisks(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      riskID
      personID
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPersonRisksQueryVariables,
  APITypes.ListPersonRisksQuery
>;
export const getPersonTasks = /* GraphQL */ `query GetPersonTasks($id: ID!) {
  getPersonTasks(id: $id) {
    id
    taskID
    personID
    task {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      complete
      category
      type
      status
      startDate
      endDate
      completedDate
      createdAt
      updatedAt
      projectTasksId
      owner
      __typename
    }
    person {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      firstName
      lastName
      email
      position
      location
      hoursPerWeek
      currentlyActive
      skills
      createdAt
      updatedAt
      tenantPeopleId
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPersonTasksQueryVariables,
  APITypes.GetPersonTasksQuery
>;
export const listPersonTasks = /* GraphQL */ `query ListPersonTasks(
  $filter: ModelPersonTasksFilterInput
  $limit: Int
  $nextToken: String
) {
  listPersonTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      taskID
      personID
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPersonTasksQueryVariables,
  APITypes.ListPersonTasksQuery
>;
export const getRoleTypePeople = /* GraphQL */ `query GetRoleTypePeople($id: ID!) {
  getRoleTypePeople(id: $id) {
    id
    roleTypeID
    personID
    roleType {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantRoleTypesId
      owner
      __typename
    }
    person {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      firstName
      lastName
      email
      position
      location
      hoursPerWeek
      currentlyActive
      skills
      createdAt
      updatedAt
      tenantPeopleId
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRoleTypePeopleQueryVariables,
  APITypes.GetRoleTypePeopleQuery
>;
export const listRoleTypePeople = /* GraphQL */ `query ListRoleTypePeople(
  $filter: ModelRoleTypePeopleFilterInput
  $limit: Int
  $nextToken: String
) {
  listRoleTypePeople(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      roleTypeID
      personID
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRoleTypePeopleQueryVariables,
  APITypes.ListRoleTypePeopleQuery
>;
export const getRoleTypeProjectRoles = /* GraphQL */ `query GetRoleTypeProjectRoles($id: ID!) {
  getRoleTypeProjectRoles(id: $id) {
    id
    roleTypeID
    projectRoleID
    roleType {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantRoleTypesId
      owner
      __typename
    }
    projectRole {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      fte
      description
      unit
      plannedUnits
      actualUnits
      unitRate
      unitCost
      billingFrequency
      startDate
      endDate
      status
      createdAt
      updatedAt
      projectProjectRolesId
      pipelineProjectRolesId
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRoleTypeProjectRolesQueryVariables,
  APITypes.GetRoleTypeProjectRolesQuery
>;
export const listRoleTypeProjectRoles = /* GraphQL */ `query ListRoleTypeProjectRoles(
  $filter: ModelRoleTypeProjectRolesFilterInput
  $limit: Int
  $nextToken: String
) {
  listRoleTypeProjectRoles(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      roleTypeID
      projectRoleID
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRoleTypeProjectRolesQueryVariables,
  APITypes.ListRoleTypeProjectRolesQuery
>;
export const csvUpload = /* GraphQL */ `query CsvUpload($input: AWSJSON!) {
  csvUpload(input: $input)
}
` as GeneratedQuery<APITypes.CsvUploadQueryVariables, APITypes.CsvUploadQuery>;
export const integrations = /* GraphQL */ `query Integrations($input: AWSJSON!) {
  integrations(input: $input)
}
` as GeneratedQuery<
  APITypes.IntegrationsQueryVariables,
  APITypes.IntegrationsQuery
>;
export const updateIntegrationData = /* GraphQL */ `query UpdateIntegrationData($input: AWSJSON!) {
  updateIntegrationData(input: $input)
}
` as GeneratedQuery<
  APITypes.UpdateIntegrationDataQueryVariables,
  APITypes.UpdateIntegrationDataQuery
>;
export const userFunctions = /* GraphQL */ `query UserFunctions($input: AWSJSON!) {
  userFunctions(input: $input)
}
` as GeneratedQuery<
  APITypes.UserFunctionsQueryVariables,
  APITypes.UserFunctionsQuery
>;
export const fetchCustomData = /* GraphQL */ `query FetchCustomData($input: AWSJSON!) {
  fetchCustomData(input: $input)
}
` as GeneratedQuery<
  APITypes.FetchCustomDataQueryVariables,
  APITypes.FetchCustomDataQuery
>;
export const contactSupport = /* GraphQL */ `query ContactSupport($input: AWSJSON!) {
  contactSupport(input: $input)
}
` as GeneratedQuery<
  APITypes.ContactSupportQueryVariables,
  APITypes.ContactSupportQuery
>;
