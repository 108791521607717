import { useContext, useMemo, useState } from "react";
import {
  Navbar,
  ScrollArea,
  createStyles,
  NativeSelect,
  ActionIcon,
  // Group,
  Tooltip,
  // UnstyledButton,
  Box,
  // ThemeIcon,
  Popover,
  // px,
  Avatar,
} from "@mantine/core";
import {
  Database,
  Gauge,
  Settings,
  Logout,
  Upload,
  Affiliate,
} from "tabler-icons-react";
import { LinksGroup } from "./LinksGroup";
import AppContext from "../../context/AppContext";
import { IconAdjustmentsHorizontal, IconWand } from "@tabler/icons-react";
import { useViewportSize } from "@mantine/hooks";
import GlobalFilters from "./GlobalFilters";
import { ContactSupport } from "../common/ContactSupport";

const navigationData = [
  {
    label: "Dashboard",
    icon: Gauge,
    link: "/dashboard",
    groupsWhoCanView: ["All"],
    viewTypesWhoCanView: ["Internal", "Customer"],
  },
  {
    label: "Data Input",
    icon: Database,
    initiallyOpened: true,
    groupsWhoCanView: ["Admin"],
    viewTypesWhoCanView: ["Internal", "Customer"],
    links: [
      {
        label: "MRR",
        module: "MRRs",
        link: "/mrrs",
        groupsWhoCanView: ["All"],
        viewTypesWhoCanView: ["Internal"],
      },
      {
        label: "Costs",
        module: "Costs",
        link: "/costs",
        groupsWhoCanView: ["All"],
        viewTypesWhoCanView: ["Internal"],
      },
      {
        label: "Profit & Loss",
        module: "PLs",
        link: "/pls",
        groupsWhoCanView: ["All"],
        viewTypesWhoCanView: ["Internal"],
      },
      {
        label: "Statements of Work",
        link: "/statements-of-work",
        groupsWhoCanView: ["All"],
        viewTypesWhoCanView: ["Internal"],
        module: "Statements of Work",
      },
      {
        label: "Deliverables",
        link: "/deliverables",
        groupsWhoCanView: ["All"],
        viewTypesWhoCanView: ["Internal"],
      },
      {
        label: "Risks",
        link: "/risks",
        groupsWhoCanView: ["All"],
        viewTypesWhoCanView: ["Internal"],
      },
      {
        label: "People",
        link: "/people",
        groupsWhoCanView: ["All"],
        viewTypesWhoCanView: ["Internal"],
      },
      {
        label: "Projects",
        link: "/projects",
        groupsWhoCanView: ["All"],
        viewTypesWhoCanView: ["Internal"],
      },
      {
        label: "CSATs",
        module: "CSATs",
        link: "/csats",
        groupsWhoCanView: ["All"],
        viewTypesWhoCanView: ["Internal", "Customer"],
      },
      {
        label: "Weekly Updates",
        module: "Weekly Updates",
        link: "/weekly-updates",
        groupsWhoCanView: ["All"],
        viewTypesWhoCanView: ["Internal", "Customer"],
      },
      {
        label: "Employee Satisfaction",
        link: "/employee-satisfaction",
        groupsWhoCanView: ["All"],
        viewTypesWhoCanView: ["Internal"],
        module: "Employee Satisfaction",
      },
      {
        label: "Invoices",
        link: "/invoices",
        groupsWhoCanView: ["All"],
        viewTypesWhoCanView: ["Internal"],
        module: "Invoices",
      },
      {
        label: "Pipeline",
        link: "/pipeline",
        groupsWhoCanView: ["All"],
        viewTypesWhoCanView: ["Internal"],
        module: "Pipelines",
      },
      {
        label: "Project Allocations",
        link: "/project-allocations",
        groupsWhoCanView: ["All"],
        viewTypesWhoCanView: ["Internal"],
        module: "Project Allocations",
      },
      {
        label: "Project Roles",
        link: "/project-roles",
        groupsWhoCanView: ["All"],
        viewTypesWhoCanView: ["Internal"],
        module: "Project Roles",
      },
      {
        label: "Role Allocations",
        link: "/role-allocations",
        groupsWhoCanView: ["All"],
        viewTypesWhoCanView: ["Internal"],
        module: "Role Allocations",
      },
      {
        label: "Role Types",
        link: "/role-types",
        groupsWhoCanView: ["All"],
        viewTypesWhoCanView: ["Internal"],
        module: "Role Types",
      },
      // {
      //   label: "Stages",
      //   link: "/stages",
      //   groupsWhoCanView: ["All"],
      //   viewTypesWhoCanView: ["Internal"],
      //   module: "Stages",
      // },
      {
        label: "Tasks",
        link: "/tasks",
        groupsWhoCanView: ["All"],
        viewTypesWhoCanView: ["Internal"],
        module: "Tasks",
      },
      {
        label: "Targets",
        link: "/targets",
        groupsWhoCanView: ["All"],
        viewTypesWhoCanView: ["Internal"],
        module: "Targets",
      },
      {
        label: "Time Sheets",
        link: "/time-sheets",
        groupsWhoCanView: ["All"],
        viewTypesWhoCanView: ["Internal"],
        module: "Time Sheets",
      },
      {
        label: "Annual Leave",
        link: "/annual-leave",
        groupsWhoCanView: ["All"],
        viewTypesWhoCanView: ["Internal"],
        module: "Annual Leave",
      },

      {
        label: "Change Management",
        link: "/change-management",
        groupsWhoCanView: ["All"],
        viewTypesWhoCanView: ["Internal", "Customer"],
        module: "Change Requests",
      },
    ],
  },
  // {
  //   label: "Data Upload",
  //   icon: Upload,
  //   link: "/data-upload",
  //   groupsWhoCanView: ["Admin"],
  //   viewTypesWhoCanView: ["Internal"],
  // },
  {
    label: "Integrations",
    icon: Affiliate,
    link: "/integrations",
    groupsWhoCanView: ["Admin"],
    viewTypesWhoCanView: ["Internal"],
  },
  // {
  //   label: "Task Genie",
  //   icon: IconWand,
  //   link: "/task-genie",
  //   groupsWhoCanView: ["Admin"],
  //   viewTypesWhoCanView: ["Internal"],
  // },
  {
    label: "Admin",
    icon: Settings,
    groupsWhoCanView: ["Admin"],
    viewTypesWhoCanView: ["Internal"],
    links: [
      {
        label: "System Preferences",
        link: "/system-preferences",
        groupsWhoCanView: ["Admin"],
        viewTypesWhoCanView: ["Internal"],
      },
      {
        label: "Manage Tenants",
        link: "/tenants",
        groupsWhoCanView: ["Admin"],
        viewTypesWhoCanView: ["Internal"],
      },
      {
        label: "Manage Verticals",
        link: "/verticals",
        groupsWhoCanView: ["Admin"],
        viewTypesWhoCanView: ["Internal"],
      },
      {
        label: "Manage Accounts",
        link: "/accounts",
        groupsWhoCanView: ["Admin"],
        viewTypesWhoCanView: ["Internal"],
      },
      {
        label: "Manage Programmes",
        link: "/programmes",
        groupsWhoCanView: ["Admin"],
        viewTypesWhoCanView: ["Internal"],
      },
      {
        label: "Custom Datasets",
        link: "/custom-datasets",
        groupsWhoCanView: ["Admin"],
        viewTypesWhoCanView: ["Internal"],
      },
      {
        label: "Manage Users",
        link: "/manage-users",
        groupsWhoCanView: ["Admin"],
        viewTypesWhoCanView: ["Internal"],
      },
      {
        label: "User Types",
        link: "/user-types",
        groupsWhoCanView: ["Admin"],
        viewTypesWhoCanView: ["Internal"],
      },
    ],
  },
];

const useStyles = createStyles((theme) => ({
  control: {
    fontWeight: 500,
    display: "block",
    width: "100%",
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
    fontSize: theme.fontSizes.sm,

    // "&:hover": {
    //   backgroundColor:
    //     theme.colorScheme === "dark"
    //       ? theme.colors.dark[7]
    //       : theme.colors.gray[0],
    //   color: theme.colorScheme === "dark" ? theme.white : theme.black,
    // },
  },
  textColor: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors[theme.primaryColor][5]
        : theme.colors[theme.primaryColor][9],
  },

  navbar: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
    paddingBottom: 0,
  },

  links: {
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
  },

  linksInner: {},

  footer: {
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
  },
}));

export function Navigation({
  opened,
  signOut,
}: {
  opened: boolean;
  signOut: any;
}) {
  const {
    preferred_username,
    groups = [],
    viewType,
    systemPreferenceObject,
  } = useContext(AppContext);

  const { classes, theme } = useStyles();
  const [filtersOpen, setFiltersOpen] = useState(false);
  const { width } = useViewportSize();

  const linkFilterFunction = useMemo(
    () => (item: any) => {
      return (
        item.viewTypesWhoCanView?.includes(viewType) &&
        (!item.module ||
          (systemPreferenceObject &&
            systemPreferenceObject.modules[item.module]?.active)) &&
        (item.groupsWhoCanView?.includes("All") ||
          item.groupsWhoCanView?.some((group: any) => groups.includes(group)))
      );
    },
    [groups, viewType, systemPreferenceObject]
  );

  const navExpanded = width < 764; //px(theme.breakpoints.sm);

  const links = navigationData.filter(linkFilterFunction).map((item) => {
    return (
      <LinksGroup
        {...item}
        key={item.label}
        navExpanded={navExpanded}
        linkFilterFunction={linkFilterFunction}
      />
    );
  });

  return (
    <Navbar
      width={navExpanded ? { sm: 200, lg: 250 } : { sm: 65, lg: 65 }}
      p="md"
      className={classes.navbar}
      hiddenBreakpoint="sm"
      hidden={!opened}
    >
      {!navExpanded ? null : (
        <Navbar.Section>
          <GlobalFilters />
        </Navbar.Section>
      )}

      <Navbar.Section
        grow
        className={classes.links}
        component={ScrollArea}
        sx={{ overflow: navExpanded ? "scroll" : "visible" }}
      >
        <div className={classes.linksInner}>
          {!navExpanded && (
            <Popover
              width={400}
              // trapFocus
              position="right-start"
              withArrow
              shadow="md"
              opened={filtersOpen}
              onChange={setFiltersOpen}
              keepMounted={true}
            >
              <Popover.Target>
                <Tooltip
                  label={"Global Filters"}
                  position="right"
                  disabled={filtersOpen}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center" }}
                    className={classes.control}
                  >
                    <ActionIcon
                      variant={"subtle"}
                      size={30}
                      color={theme.primaryColor}
                      onClick={() => setFiltersOpen((o) => !o)}
                    >
                      <IconAdjustmentsHorizontal size={18} />
                    </ActionIcon>
                  </Box>
                </Tooltip>
              </Popover.Target>
              <Popover.Dropdown
                sx={(theme) => ({
                  background:
                    theme.colorScheme === "dark"
                      ? theme.colors.dark[9]
                      : undefined,
                })}
              >
                <GlobalFilters setFiltersOpen={setFiltersOpen} />
              </Popover.Dropdown>
            </Popover>
          )}

          {links}
        </div>
      </Navbar.Section>

      <Navbar.Section className={classes.footer}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {!navExpanded && (
            <>
              <Avatar color={theme.primaryColor} radius="xl">
                {preferred_username ?? "U"}
              </Avatar>
              <Tooltip label={"Contact Support"} position="right">
                <Box className={classes.control}>
                  <ContactSupport />
                </Box>
              </Tooltip>
            </>
          )}

          <Tooltip label={"Log Out"} position="right">
            <Box className={classes.control}>
              <ActionIcon
                variant={"subtle"}
                size={30}
                color={theme.primaryColor}
                onClick={() => {
                  signOut();
                  // navigate("/login");
                }}
              >
                <Logout size={18} />
              </ActionIcon>
            </Box>
          </Tooltip>
        </Box>
      </Navbar.Section>
    </Navbar>
  );
}
