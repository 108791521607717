import {
  Box,
  Divider,
  Group,
  Switch,
  createStyles,
  getStylesRef,
} from "@mantine/core";
import { useContext, useEffect, useMemo, useState } from "react";
import AppContext from "../../../../context/AppContext";
import { VerticalTile } from "./VerticalTile";
import WidgetFrame from "../../WidgetFrame";
import { Carousel, Embla } from "@mantine/carousel";
import useLoadingStatus from "../../../../hooks/useLoadingStatus";
import { createFilterFunction } from "../../functions/createFilterFunction";
import useFetchData from "../../../../hooks/useFetchData";
import { IconCalendar } from "@tabler/icons-react";
import { getTargetValueFromListOfTargets } from "../../functions/getTargetValueFromListOfTargets";
import { getRevenueStatusObjectFromProjectList } from "../../functions/getRevenueStatusObjectFromProjectList";

const useStyles = createStyles(() => ({
  controls: {
    ref: getStylesRef("controls"),
    transition: "opacity 150ms ease",
    opacity: 0,
  },

  root: {
    "&:hover": {
      [`& .${getStylesRef("controls")}`]: {
        opacity: 1,
      },
    },
  },
}));

export default function VerticalSummary(props: any) {
  const {
    dashboardFilterObjectList,
    updateDashboardFilter,
    globalFilteredData = [],
    startDate,
    endDate,
  } = props;

  const { classes } = useStyles();

  const {
    dataObject: {
      verticals = [],
      projects = [],
      programmes = [],
      risks = [],
      tenants = [],
    },
    currentTenant,
    currentVertical,
    currentAccount,
    currentProgramme,
    setCurrentVertical,
    currentProgrammes,
  } = useContext(AppContext);

  const [embla, setEmbla] = useState<Embla | null>(null);
  const [visibleSlides, setVisibleSlides] = useState<number[]>([]);
  const [currentSlide, setCurrentSlide] = useState<number | null>(0);
  const [showRevenue, setShowRevenue] = useState<boolean>(false);

  useFetchData(["Verticals", "Projects", "Programmes", "Risks"], ["Live"]);

  const localFilterFunction = useMemo(
    () =>
      createFilterFunction(
        [dashboardFilterObjectList],
        "associatedDealCloseDate", //"startDate",
        startDate,
        endDate
      ),
    [dashboardFilterObjectList]
  );

  const loading = useLoadingStatus([
    "verticals",
    "projects",
    "programmes",
    "risks",
  ]);

  const filteredVerticals = useMemo(
    () =>
      currentVertical === "All"
        ? verticals.filter((vertical: any) => vertical.period === "Live")
        : verticals.filter((vertical: any) => {
            return vertical.id === currentVertical;
          }),
    [currentVertical, verticals]
  );

  const filteredProjects = useMemo(() => {
    return globalFilteredData
      .filter((project: any) =>
        currentProgrammes.includes(project.programmeProjectsId)
      )
      .filter(localFilterFunction);
  }, [globalFilteredData, localFilterFunction, currentProgrammes]);

  const groupedProjects = useMemo(() => {
    //@ts-ignore
    return Object.groupBy(
      filteredProjects,
      (row: any) => row?.programme?.verticalProgrammesId
    );
  }, [filteredProjects]);

  const projectObject = useMemo(() => {
    return Object.keys(groupedProjects).reduce((obj: any, id: any) => {
      //@ts-ignore
      obj[id] = getRevenueStatusObjectFromProjectList(groupedProjects[id]);
      return obj;
    }, {});
  }, [groupedProjects]);

  const entireObject = useMemo(() => {
    return getRevenueStatusObjectFromProjectList(filteredProjects);
  }, [filteredProjects]);

  const entireTarget = useMemo(() => {
    const targets = verticals.reduce((list: any, vertical: any) => {
      list = list.concat(vertical.targets.items);
      return list;
    }, []);

    const target = getTargetValueFromListOfTargets(targets, startDate, endDate);
    return target;
  }, [verticals, startDate, endDate]);

  useEffect(() => {
    if (embla) {
      const slidesInView = embla.slidesInView() ?? [0];
      setVisibleSlides([
        slidesInView[0] - 1,
        ...slidesInView, //@ts-ignore
        slidesInView.at(-1) + 1,
      ]);
    }
  }, [embla, filteredVerticals, currentSlide]);

  const showTarget = useMemo(() => {
    return currentAccount === "All" && currentProgramme === "All";
  }, [currentAccount, currentProgramme]);

  return (
    <WidgetFrame
      title={"Channel Summary"}
      icon={IconCalendar}
      controls={
        <Group>
          <Switch
            label="Show Revenue"
            checked={showRevenue}
            onChange={(e) => setShowRevenue(e.target.checked)}
          />
        </Group>
      }
    >
      <Group sx={{ overflow: "hidden" }} noWrap>
        <Box
          style={{
            width: false && showRevenue ? 400 : 200,
            minWidth: false && showRevenue ? 400 : 200,
          }}
        >
          <VerticalTile
            name={
              tenants.find((tenant: any) => tenant.id === currentTenant)
                ?.name ?? ""
            }
            showTarget={showTarget}
            target={entireTarget}
            setCurrentVertical={setCurrentVertical}
            currentVertical={currentVertical}
            id={"Total"}
            loading={loading}
            tenant={currentTenant}
            totalsObject={entireObject}
            showRevenue={false && showRevenue}
            stats={[
              // {
              //   value: programmes.filter(
              //     (prog: any) =>
              //       prog.verticalProgrammesId === vertical.id &&
              //       prog.period === "Live"
              //   ).length,
              //   label: "Programmes",
              // },
              {
                value: filteredProjects.filter(
                  (project: any) => project.period === "Live"
                ).length,
                label: "Projects",
              },
              // {
              //   value: risks.filter(
              //     (risk: any) =>
              //       risk.verticalRisksId === vertical.id &&
              //       risk.period === "Live"
              //   ).length,
              //   label: "Risks",
              // },
              {
                value: risks.filter((risk: any) => {
                  return (
                    risk.status === "Open" &&
                    risk.period === "Live" &&
                    risk.impact * risk.probability >= 20
                  );
                }).length,
                label: "High Risks",
              },
            ]}
          />
        </Box>
        <Divider orientation="vertical" />

        <Carousel
          // mx="auto"
          align="start"
          slideGap="md"
          controlsOffset="xs"
          controlSize={21}
          px={"md"}
          // withIndicators
          classNames={classes}
          getEmblaApi={setEmbla}
          onSlideChange={setCurrentSlide}
          style={{ overflow: "hidden", flexGrow: 1 }}
        >
          {filteredVerticals
            .sort((a: any, b: any) => {
              return a.name === b.name ? 0 : a.name > b.name ? -1 : 1;
            })
            .sort((a: any, b: any) => {
              return (
                (projectObject[a.id]?.total ?? 0) -
                (projectObject[b.id]?.total ?? 0)
              );
            })
            .reverse()
            .map((vertical: any, index: number) => {
              const targets = vertical.targets.items;

              const target = getTargetValueFromListOfTargets(
                targets,
                startDate,
                endDate
              );

              return (
                <Carousel.Slide
                  size={showRevenue ? 400 : 200}
                  key={vertical.id}
                  p={5}
                >
                  {visibleSlides.includes(index) ? (
                    <VerticalTile
                      name={vertical.name}
                      showTarget={showTarget}
                      target={target}
                      setCurrentVertical={setCurrentVertical}
                      currentVertical={currentVertical}
                      id={vertical.id}
                      loading={loading}
                      tenant={currentTenant}
                      totalsObject={projectObject[vertical.id] ?? null}
                      showRevenue={showRevenue}
                      stats={[
                        // {
                        //   value: programmes.filter(
                        //     (prog: any) =>
                        //       prog.verticalProgrammesId === vertical.id &&
                        //       prog.period === "Live"
                        //   ).length,
                        //   label: "Programmes",
                        // },
                        {
                          value: filteredProjects.filter(
                            (project: any) =>
                              project.programme?.verticalProgrammesId ===
                                vertical.id && project.period === "Live"
                          ).length,
                          label: "Projects",
                        },
                        // {
                        //   value: risks.filter(
                        //     (risk: any) =>
                        //       risk.verticalRisksId === vertical.id &&
                        //       risk.period === "Live"
                        //   ).length,
                        //   label: "Risks",
                        // },
                        {
                          value: risks.filter((risk: any) => {
                            return (
                              risk.verticalAccessString === vertical.id &&
                              risk.status === "Open" &&
                              risk.period === "Live" &&
                              risk.impact * risk.probability >= 20
                            );
                          }).length,
                          label: "High Risks",
                        },
                      ]}
                    />
                  ) : null}
                </Carousel.Slide>
              );
            })}
        </Carousel>
      </Group>
    </WidgetFrame>
  );
}
