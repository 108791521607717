function percentageDifferenceFunction(currentValue: any, oldValue: any) {
  return oldValue === 0
    ? "-"
    : Math.round(((currentValue - oldValue) / oldValue) * 100) - 100;
}

function getAverageOfListOfNumbers(numbers: number[]): number {
  if (numbers.length === 0) {
    return 0;
  }
  return numbers.reduce((sum, num) => sum + num, 0) / numbers.length;
}

export { percentageDifferenceFunction, getAverageOfListOfNumbers };
