import { TenantQuestion } from "../../components/common/TenantQuestion";
import { ISchema } from "../SchemaTypes";
import common from "./commonSchema";
// import { formatDateString } from "../../components/common/functions/formatFunctions";

const programmesSchema: ISchema = {
  tenant: {
    title: "Tenant",
    field: "tenant",
    accessorFunction: (value: any) => value.tenant?.name,
    formOptions: { required: true },
    excludeFromForm: true,
    type: "belongsTo",
  },
  tenantProgrammesId: {
    title: "Tenant",
    field: "tenantProgrammesId",
    formOptions: {
      required: true,
      CustomQuestion: (props: any) => (
        <TenantQuestion {...props} fieldName="tenantProgrammesId" />
      ),
      options: {
        dataSource: "tenants",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource?.map((row: any) => {
            return { value: row.id, label: row.name };
          }) ?? [],
      },
      order: 1,
    },
    type: "select",
  },
  integrationSystemId: {
    title: "Integration System ID",
    field: "integrationSystemId",
    excludeFromForm: true,
    excludeFromChartBuilder: true,
    formOptions: {
      required: false,
    },
  },
  account: {
    title: "Account",
    field: "account",
    accessorFunction: (row: any) => row.account?.name,
    formOptions: { required: true },
    excludeFromForm: true,
    type: "belongsTo",
  },
  accountProgrammesId: {
    title: "Account",
    field: "accountProgrammesId",
    formOptions: {
      required: true,
      options: {
        dataSource: "accounts",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource.map((row: any) => {
            return { value: row.id, label: row.name };
          }),
      },
      order: 1,
    },
    type: "select",
  },
  vertical: {
    title: "Vertical",
    field: "vertical",
    accessorFunction: (value: any) => value.vertical?.name,
    formOptions: { required: true },
    excludeFromForm: true,
    type: "belongsTo",
  },
  verticalProgrammesId: {
    title: "Vertical",
    field: "verticalProgrammesId",
    formOptions: {
      required: true,
      options: {
        dataSource: "verticals",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource.map((row: any) => {
            return { value: row.id, label: row.name };
          }),
      },
      order: 1,
    },
    type: "select",
  },
  name: { title: "Name", field: "name", formOptions: { required: true } },

  risks: {
    title: "Risks",
    field: "risks",
    type: "hasMany",
    accessorFunction: (row: any) => row.risks?.length ?? 0,
    formOptions: { required: true },
    excludeFromForm: true,
  },
  statements: {
    title: "Statements of Work",
    field: "statements",
    type: "hasMany",
    accessorFunction: (row: any) => row.statements?.length ?? 0,
    formOptions: { required: true },
    excludeFromForm: true,
  },
  people: {
    title: "People",
    field: "people",
    type: "hasMany",
    accessorFunction: (row: any) => row.people?.length ?? 0,
    formOptions: { required: true },
    excludeFromForm: true,
  },
  changes: {
    title: "Changes",
    field: "changes",
    type: "hasMany",
    accessorFunction: (row: any) => row.changes?.length ?? 0,
    formOptions: { required: true },
    excludeFromForm: true,
  },
  csats: {
    title: "CSATs",
    field: "csats",
    type: "hasMany",
    accessorFunction: (row: any) => row.csats?.length ?? 0,
    formOptions: { required: true },
    excludeFromForm: true,
  },
  weeklyUpdates: {
    title: "Weekly Updates",
    field: "weeklyUpdates",
    type: "hasMany",
    accessorFunction: (row: any) => row.weeklyUpdates?.length ?? 0,
    formOptions: { required: true },
    excludeFromForm: true,
  },
  projects: {
    title: "Projects",
    field: "projects",
    type: "hasMany",
    accessorFunction: (row: any) => row.projects?.length ?? 0,
    formOptions: { required: true },
    excludeFromForm: true,
  },

  mrrs: {
    title: "MRRs",
    field: "mrrs",
    type: "hasMany",
    accessorFunction: (row: any) => row.projects?.length ?? 0,
    formOptions: { required: true },
    excludeFromForm: true,
  },

  pls: {
    title: "PLs",
    field: "pls",
    type: "hasMany",
    accessorFunction: (row: any) => row.projects?.length ?? 0,
    formOptions: { required: true },
    excludeFromForm: true,
  },

  costs: {
    title: "Costs",
    field: "costs",
    type: "hasMany",
    accessorFunction: (row: any) => row.projects?.length ?? 0,
    formOptions: { required: true },
    excludeFromForm: true,
  },

  ...common,
};

export default programmesSchema;
