import { widgetsObject } from "../widgetsObject";

export default function getPreBuiltWidget({
  widgetName,
  dashboardFilterObjectList,
  updateDashboardFilter,
  globalFilteredData,
  comparisonGlobalFilteredData,
  comparisonFieldString,
  startDate,
  endDate,
}: {
  widgetName: string;
  dashboardFilterObjectList: any;
  updateDashboardFilter: any;
  globalFilteredData: any;
  comparisonGlobalFilteredData: any;
  comparisonFieldString: any;
  startDate: any;
  endDate: any;
}) {
  //@ts-ignore
  const widgetInfo = widgetsObject[widgetName];
  return (
    <widgetInfo.component
      dashboardFilterObjectList={dashboardFilterObjectList}
      updateDashboardFilter={updateDashboardFilter}
      globalFilteredData={globalFilteredData ?? []}
      comparisonGlobalFilteredData={comparisonGlobalFilteredData}
      comparisonFieldString={comparisonFieldString}
      startDate={startDate}
      endDate={endDate}
    />
  );
  // return AddExtraProps(widgetsObject[widgetName].component, propsObject);
}
